<template>
  <DashboardHeader title="Dashboard" :icon="icon.dashboard">
    <template v-slot:aside>
      <div class="bg-[#DCE9E3] px-3 py-2 rounded-[0.327rem] flex items-center">
        <span class="font-poppins font-medium sm:text-[0.8rem] text-[0.6rem] text-[#959CBD]">Today:
          <span class="text-[#4E8D6D] font-semibold">{{ today }}</span></span>
        <img src="../../assets/today.png" alt="africa" class="w-[0.8rem] h-[0.8rem] rounded ml-1" />
      </div>
    </template>
  </DashboardHeader>
  <div class="w-full my-3">
    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mt-6">
      <div class="justify-center" v-if="
        loggedInUser.partner?.plan.name === 'Kiosk' ||
        loggedInUser.partner?.plan.name === 'Premium'
      ">
        <CardDesign :data="{
          topic: 'TOTAL KIOSKS',
          icon: 'fa-solid fa-building',
          figures: totalKiosks,
          color: '#FFF3D6',
          iconcolor: '#FEC53D',
        }" />
      </div>

      <div class="justify-center">
        <CardDesign :data="{
          topic: 'TOTAL ROUTERS',
          icon: 'fa-solid fa-briefcase',
          figures: totalRouters,
          iconcolor: '#FF9066',
          color: '#FFDED1',
        }" />
      </div>


      <div class="justify-center" v-if="
        loggedInUser.partner?.plan.name === 'SME' ||
        loggedInUser.partner?.plan.name === 'Premium'
      ">
        <CardDesign :data="{
          topic: 'TOTAL SMES',
          icon: 'fa-solid fa-briefcase',
          figures: totalSmes,
          color: '#E5E4FF',
          iconcolor: '#8280FF',
        }" />
      </div>

      <div class="justify-center" v-if="
        loggedInUser.partner?.plan.name === 'Kiosk' ||
        loggedInUser.partner?.plan.name === 'Premium'
      ">
        <CardDesign :data="{
          topic: 'M.FRANCHISEES',
          icon: 'fa-solid fa-person-running',
          figures: totalMicroFranchisees,
          color: '#4AD991',
        }" />
      </div>
    </div>

    <div class="w-full lg:flex my-6 justify-between" v-if="
      loggedInUser.partner?.plan.name === 'Kiosk' ||
      loggedInUser.partner?.plan.name === 'Premium'
    ">
      <div
        class="lg:w-[64%] justify-center text-6xl shadow-[0_0px_10px_0px_rgba(0,0,0,0.13)] rounded-xl p-6 bg-gray-100 bg-white lg:mb-0 mb-6">
        <h5 class="mb-4 text-base font-bold text-[#000]">
          Micro Franchisee Sign Up
        </h5>
        <apexchart type="area" height="400" :options="Options" :series="Series"></apexchart>
      </div>
      <div
        class="lg:w-[34%] justify-center text-6xl shadow-[0_0px_10px_0px_rgba(0,0,0,0.13)] rounded-xl p-6 bg-gray-100 bg-white">
        <h5 class="mb-4 text-base font-bold text-[#000]">
          M.Franchisee Distribution by gender
        </h5>
        <apexchart type="line" height="500" :options="genderOptions" :series="genderSeries"></apexchart>
      </div>
    </div>

    <div class="w-full xl:flex lg:block my-6 justify-between" v-if="
      loggedInUser.partner?.plan.name === 'Kiosk' ||
      loggedInUser.partner?.plan.name === 'Premium'
    ">
      <div
        class="xl:w-[49%] lg:w-full justify-center text-6xl shadow-[0_0px_10px_0px_rgba(0,0,0,0.13)] rounded-xl p-6 bg-gray-100 bg-white xl:mb-0 mb-6">
        <h5 class="mb-4 text-base font-bold text-[#4e8d6e]">
          Analytics For Today
          <span class="text-[#000]">Compared To Yesterday</span>
        </h5>
        <div class="count text-sm grid md:grid-cols-3 sm:grid-cols-2 rid-cols-1 gap-4">
          <div class="justify-center text-6xl p-4 bg-light rounded-md relative pb-10">
            <h5 class="text-sm font-semibold text-[#707070] max-w-[170px]">
              Today's Total Commission Amount
            </h5>
            <div class="count text-sm font-bold absolute bottom-[13px]">
              CAF 200
            </div>
          </div>

          <div class="justify-center text-6xl p-4 bg-light rounded-md relative pb-10">
            <h5 class="text-sm font-semibold text-[#707070] max-w-[170px]">
              Today's MF Commission Amount
            </h5>
            <div class="count text-sm font-bold absolute bottom-[13px]">
              CAF 200
            </div>
          </div>

          <div class="justify-center text-6xl p-4 bg-light rounded-md relative pb-10">
            <h5 class="text-sm font-semibold text-[#707070] max-w-[170px]">
              Today's Turnover<br />
              Amount
            </h5>

            <div class="count text-sm font-bold absolute bottom-[13px]">
              CAF 200
            </div>
          </div>

          <div class="justify-center text-6xl p-4 bg-light rounded-md relative pb-10">
            <h5 class="text-sm font-semibold text-[#707070] max-w-[170px]">
              Highest MF<br />
              Commission
            </h5>
            <div class="count text-sm font-bold absolute bottom-[13px]">
              CAF 200
            </div>
          </div>

          <div class="justify-center text-6xl p-4 bg-light rounded-md relative pb-10">
            <h5 class="text-sm font-semibold text-[#707070] max-w-[170px]">
              Highest MF Commission Yesterday
            </h5>
            <div class="count text-sm font-bold absolute bottom-[13px]">
              CAF 120
            </div>
          </div>

          <div class="justify-center text-6xl p-4 bg-light rounded-md relative pb-10">
            <h5 class="text-sm font-semibold text-[#707070] max-w-[170px]">
              Highest MF Commission This Month
            </h5>

            <div class="count text-sm font-bold absolute bottom-[13px]">
              CAF 800
            </div>
          </div>
        </div>
      </div>
      <div
        class="xl:w-[49%] lg:w-full justify-center text-6xl shadow-[0_0px_10px_0px_rgba(0,0,0,0.13)] rounded-xl p-6 bg-gray-100 bg-white">
        <h5 class="mb-4 text-base font-bold text-[#000]">
          Service Distribution
        </h5>
        <apexchart height="500" type="donut" :options="serviceoptions" :series="serviceseries" :labels="servicelabels">
        </apexchart>
      </div>
    </div>
  </div>

  <div class="min-server text-right mb-2 flex items-center">
    <select v-model="selectedRouter" @change="handleRouterChange"
      class="text-[14px] max-w-[250px] ml-auto border-[0rem] bgwhite rounded-[0.4rem] px-3 py-2 border-textFieldBorder border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full focus:ring-1">
      <option value="all">All Boxes</option>
      <option v-for="router in loggedInUser.partner.routers" :key="router.serial_number" :value="router.serial_number">
        {{ router.ssid ? router.ssid + ' - ' + router.serial_number : router.serial_number }}
      </option>
    </select>
  </div>

  <div class="shadow-[0_0px_10px_0px_rgba(0,0,0,0.13)] rounded-xl p-6 bg-gray-100 bg-white mt-6">
    <TokenAnalyticsChart :data="tokenAnalyticsData" @filterChange="fetchTokenAnalyticsData"
      :currentTimeRange="currentTimeRange" />
  </div>
</template>
<script lang="ts">
import { defineComponent, ref, provide } from 'vue';

import {
  routers as _routers,
  routerTableHeaders as _routerTableHeaders,
  routerTableIcons as _routerTableIcons,
  dashboardHeadericon,
} from '../../helpers/stub_data/dashboard_routers';

import DashboardHeader from '../../components/markup/DashboardHeader.vue';
import moment from 'moment';
import instance from '../../axios-interceptor';
import ApexCharts from 'vue3-apexcharts';
import { mapGetters } from 'vuex';
import CardDesign from "@/components/Common/CardDesign.vue";

import TokenAnalyticsChart from '@/components/Common/TokenAnalyticsChart.vue';
import { TokenAnalyticsPayload } from '@/interfaces/router';


export default defineComponent({
  name: 'HomePage',
  components: {
    apexchart: ApexCharts,
    DashboardHeader,
    CardDesign,
    TokenAnalyticsChart,
  },
  setup() {
    const icon = ref({ ...dashboardHeadericon });
    const tableName = ref('Serial Number');

    const routers = ref([..._routers]);
    const routerTableHeaders = ref([..._routerTableHeaders]);
    const routerTableIcons = ref([..._routerTableIcons]);
    const center = ref({ lat: -1.8899577, lng: 30.0634073 });
    const markers = ref([
      {
        position: { lat: -1.8899577, lng: 30.0634073 },
      },
    ]);
    const fetchingRoutersAndSMEData = ref(false);
    const fetchingTokenAnalyticsData = ref(false);
    const currentTimeRange = '3months';

    provide('loadingOne', fetchingRoutersAndSMEData);
    provide('loadingTwo', fetchingTokenAnalyticsData);


    return {
      routers,
      routerTableHeaders,
      routerTableIcons,
      center,
      markers,
      tableName,
      icon,
      fetchingRoutersAndSMEData,
      fetchingTokenAnalyticsData,
      currentTimeRange,
    };
  },
  data() {
    return {
      insightAnalytics: {} as any,
      wifiAnalytics: {} as any,
      selectedRange: 'all_days',
      totalDevices: 0,
      activeUsers: 0,
      totalTokens: 0,
      usedTokens: 0,
      totalMicroFranchisees: 0,
      totalKiosks: 0,
      totalSmes: 0,
      totalRouters: 0,
      tokenAnalyticsData: null,
      selectedRouter: 'all',
      routerStatus: false,
      Options: {
        xaxis: {
          categories: [
            'Jan',
            'Feb',
            'March',
            'April',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec',
          ],
          title: {
            text: 'MicroFranchisee Sign Ups',
          },
        },
        zoom: {
          type: 'x',
          enabled: true,
          autoScaleYaxis: false,
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'smooth',
        },
      },
      Series: [
        {
          name: 'Signed Up',
          data: [100, 70, 102, 77, 110, 112, 80, 78, 94, 67, 70, 180],
        },
      ],
      chartOptions: {
        xaxis: {
          categories: [
            'Jan',
            'Feb',
            'March',
            'April',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec',
          ],
          title: {
            text: 'Overview',
          },
        },
        zoom: {
          type: 'x',
          enabled: true,
          autoScaleYaxis: false,
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'smooth',
        },
      },
      chartSeries: [
        {
          name: 'data used',
          data: [100, 70, 102, 77, 110, 112, 80, 78, 94, 67, 70, 180],
        },
        {
          name: 'people connected',
          data: [20, 30, 24, 50, 26, 30, 25, 15, 40, 20, 55, 60],
        },
      ],
      serviceoptions: {
        labels: ['Airtime', 'Electricity'],
      },
      servicelabels: ['Airtime', 'Electricity'],
      serviceseries: [2000, 300],
      genderOptions: {
        labels: ['Female', 'Male'],
        chart: {
          id: 'horizontal-line',
        },
        yaxis: {
          categories: [''],
        },
      },
      genderSeries: [
        {
          name: 'Horizontal Line',
          data: [4],
        },
      ],
      genderlabels: ['Female', 'Male'],
    };
  },
  methods: {
    async fetchWifiAnalytics() {

      console.log("TOTAL DEVICES - BEFORE: ", this.totalDevices);
      console.log("ACTIVE USERS: ", this.activeUsers);
      console.log("TOTAL TOKENS: ", this.totalTokens);
      console.log("USED TOKENS: ", this.usedTokens);


      // TODO: FETCH ANALYTICS WITH MULTIPLE SERIAL NUMBERS
      if (!this.loggedInUser.partner.routers.length) {
        return;
      }

      const data = {
        partner: this.loggedInUser.partner.id,
        router_serial_numbers: this.loggedInUser.partner.routers.map((router: any) => router.serial_number),
      };

      await instance.post(`analytics/wifianalytics/`, data).then((response) => {
        this.wifiAnalytics = response.data.results;
        console.log(response.data)
        this.updateActiveData();
      });

      console.log("TOTAL DEVICES: ", this.totalDevices);
      console.log("ACTIVE USERS: ", this.activeUsers);
      console.log("TOTAL TOKENS: ", this.totalTokens);
      console.log("USED TOKENS: ", this.usedTokens);


    },
    async fetchGeneralAnalytics(id: any) {
      this.fetchingRoutersAndSMEData = true;
      await instance
        .get(`partners/${id}/general-analytics/`)
        .then((response) => {
          if (response.status === 200) {
            this.totalMicroFranchisees = response.data.total_micro_franchisees;
            this.totalKiosks = response.data.total_kiosks;
            this.totalSmes = response.data.total_smes;
            this.totalRouters = response.data.total_routers;
            this.fetchingRoutersAndSMEData = false;
          } else {
            this.fetchingRoutersAndSMEData = false;
            this.$toast.error('Could not fetch micro franchisee analytics', {
              position: 'top-right',
            });
          }
        });
    },
    updateActiveData() {
      const range = this.selectedRange;
      this.wifiAnalytics = {
        ...this.wifiAnalytics,
        currently_connected:
          this.wifiAnalytics.currently_connected_ranges[range],
        finished_partner_vouchers:
          this.wifiAnalytics.finished_partner_vouchers_ranges[range],
        router_restart_partner_vouchers:
          this.wifiAnalytics.router_restart_partner_vouchers_ranges[range],
        token_failed: this.wifiAnalytics.token_failed_ranges[range],
        total_device: this.wifiAnalytics.total_device_ranges[range],
        totals_generated: this.wifiAnalytics.totals_generated_ranges[range],
        unfinished_partner_voucher:
          this.wifiAnalytics.unfinished_partner_voucher_ranges[range],
        users_not_access_system:
          this.wifiAnalytics.users_not_access_system_ranges[range],
        average_duration: this.wifiAnalytics.average_duration_ranges[range],
      };
    },
    async fetchTokenAnalyticsData(newTimeRange: string) {
      if (!this.loggedInUser.partner.routers.length) {
        return;
      }

      const payload: TokenAnalyticsPayload = {
        partner: this.loggedInUser.partner.id,
      };

      if (this.selectedRouter === 'all') {
        payload.router_serial_numbers = this.loggedInUser.partner.routers.map((router: any) => router.serial_number);
      } else {
        payload.router_serial_number = this.selectedRouter;
      }

      this.currentTimeRange = newTimeRange;

      try {
        this.fetchingTokenAnalyticsData = true;
        const response = await instance.post(`/analytics/wifi-v2/?time_range=${newTimeRange}`, payload);
        this.tokenAnalyticsData = response.data;
        this.fetchingTokenAnalyticsData = false;

        // Update router status if a single router is selected
        if (this.selectedRouter !== 'all') {
          this.routerStatus = response.data.is_online || false;
        }
      } catch (error) {
        this.fetchingTokenAnalyticsData = false;
        console.error('Error fetching data:', error);
      }
    },

    handleRouterChange() {
      this.fetchTokenAnalyticsData(this.currentTimeRange);
    },
  },
  computed: {
    ...mapGetters(['loggedInUser']),
    today() {
      return moment(Date.now()).format('MMMM Do');
    },
  },
  mounted() {
    this.fetchWifiAnalytics();
    this.fetchGeneralAnalytics(this.loggedInUser.partner.id);
    this.fetchTokenAnalyticsData('3months');
  },
});
</script>
