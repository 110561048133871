<template>
  <DashboardHeader title="Analytics" :icon="icon.accountIcon">
    <template v-slot:aside>
      <div class="bg-[#DCE9E3] px-3 py-2 rounded-[0.327rem] flex items-center">
        <span class="font-poppins font-medium text-[0.8rem] text-[#959CBD]">Today:
          <span class="text-[#4E8D6D] font-semibold">{{ today }}</span></span>
        <img src="../../assets/today.png" alt="africa" class="w-[0.8rem] h-[0.8rem] rounded ml-1" />
      </div>
    </template>
  </DashboardHeader>
  <div class="w-full my-3">
    <div class="bg-white w-full rounded-[0.938rem] p-6 shadow overflow-auto relative">
      <div class="tabs-block" id="tabs-block">
        <div class="">
          <ul class="flex flex-wrap text-sm font-medium text-center mb-2" id="myTab" data-tabs-toggle="#myTabContent"
            role="tablist">
            <li class="mr-2" v-if="loggedInUser.partner?.plan.name === 'Kiosk' ||
              loggedInUser.partner?.plan.name === 'Premium'
            ">
              <button class="inline-block p-4 py-2 rounded-md w-full" id="microfranchisee"
                data-tabs-target="#microfranchisee" type="button" role="tab" aria-controls="microfranchisee"
                aria-selected="false" @click="setActiveTab('microfranchise')" v-bind:class="[
                  activeTab === 'microfranchise'
                    ? 'bg-light text-[#4e8d6e]'
                    : 'bg-[transparent]',
                ]">
                Micro Franchisee
              </button>
            </li>
            <li class="mr-2" v-if="loggedInUser.partner?.plan.name === 'SME' ||
              loggedInUser.partner?.plan.name === 'Premium'
            ">
              <button class="inline-block p-4 py-2 rounded-md w-full" id="routers" data-tabs-target="#routers"
                type="button" role="tab" aria-controls="routers" aria-selected="false" @click="setActiveTab('routers')"
                v-bind:class="[
                  activeTab === 'routers'
                    ? 'bg-light text-[#4e8d6e]'
                    : 'bg-[transparent]',
                ]">
                Routers
              </button>
            </li>
            <li class="mr-2" v-if="loggedInUser.partner?.plan.name === 'SME' ||
              loggedInUser.partner?.plan.name === 'Premium'
            ">
              <button class="inline-block p-4 py-2 rounded-md w-full" id="revenue-insights"
                data-tabs-target="#revenue-insights" type="button" role="tab" aria-controls="revenue-insights"
                aria-selected="false" @click="setActiveTab('revenue-insights')" v-bind:class="[
                  activeTab === 'revenue-insights'
                    ? 'bg-light text-[#4e8d6e]'
                    : 'bg-[transparent]',
                ]">
                Revenue Insights
              </button>
            </li>
            <li class="mr-2" v-if="loggedInUser.partner?.plan.name === 'SME' ||
              loggedInUser.partner?.plan.name === 'Premium'
            ">
              <button class="inline-block p-4 py-2 rounded-md w-full" id="advertviews" data-tabs-target="#advertviews"
                type="button" role="tab" aria-controls="advertviews" aria-selected="false"
                @click="setActiveTab('advertviews')" v-bind:class="[
                  activeTab === 'advertviews'
                    ? 'bg-light text-[#4e8d6e]'
                    : 'bg-[transparent]',
                ]">
                Advert Views
              </button>
            </li>
            <li class="mr-2" v-if="
              loggedInUser.partner?.plan.name === 'SME' ||
              loggedInUser.partner?.plan.name === 'Premium'
            ">
              <button class="inline-block p-4 py-2 rounded-md w-full" id="routers" data-tabs-target="#routers"
                type="button" role="tab" aria-controls="routers" aria-selected="false" @click="setActiveTab('sms')"
                v-bind:class="[
                  activeTab === 'sms'
                    ? 'bg-light text-[#4e8d6e]'
                    : 'bg-[transparent]',
                ]">
                SMS Insights
              </button>
            </li>
          </ul>
        </div>
        <div id="TabContent">
          <div
            v-if="activeTab === 'microfranchise' && loggedInUser.partner?.plan.name === 'Kiosk' || loggedInUser.partner?.plan.name === 'Premium'"
            class="" id="microfranchise" role="tabpanel" aria-labelledby="microfranchise">
            <div class="block sm:grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-6 mt-6">
              <div class="justify-center">
                <CardDesign :data="{
                  topic: 'TOTAL M.FRANCHISEES',
                  icon: 'fa-solid fa-users',
                  figures: totalMicroFranchisees,
                  color: '#E5E4FF',
                  iconcolor: '#8280FF',
                }" />
              </div>

              <div class="justify-center">
                <CardDesign :data="{
                  topic: 'MALE M.FRANCHISEES',
                  icon: 'fa-solid fa-person',
                  figures: maleMicroFranchisees,
                  color: '#FFF3D6',
                  iconcolor: '#FEC53D',
                }" />
              </div>

              <div class="justify-center">
                <CardDesign :data="{
                  topic: 'FEMALE M.FRANCHISEES',
                  icon: 'fa-solid fa-person-dress',
                  figures: femaleMicroFranchisees,
                  color: '#4AD991',

                }" />
              </div>


              <div class="justify-center">
                <CardDesign :data="{
                  topic: 'ACTIVE M.FRANCHISEES',
                  icon: 'fa-solid fa-person-running',
                  figures: activeMicroFranchisees,
                  iconcolor: '#FF9066',
                  color: '#FFDED1'
                }" />
              </div>

              <div class="justify-center">
                <CardDesign :data="{
                  topic: 'TOTAL M.F REVENUE',
                  icon: 'fa-solid fa-wallet',
                  figures: 'CAF 1023',
                  iconcolor: '#8280FF',
                  color: '#E5E4FF'
                }" />
              </div>

              <div class="justify-center">
                <CardDesign :data="{
                  topic: 'TOTAL M.F COMMISSION',
                  icon: 'fa-solid  fa-hand-holding-dollar',
                  figures: 'CAF 1023',
                  color: '#FFF3D6',
                  iconcolor: '#FEC53D',
                }" />
              </div>

              <div class="justify-center">
                <CardDesign :data="{
                  topic: 'M.F REVENUE TODAY',
                  icon: 'fa-solid fa-calendar-day',
                  figures: 'CAF 1023',
                  color: '#4AD991',
                }" />
              </div>

              <div class="justify-center">
                <CardDesign :data="{
                  topic: 'TOTAL M.F COMMISSION TODAY',
                  icon: 'fa-solid fa-business-time',
                  figures: 'CAF 1023',
                  iconcolor: '#FF9066',
                  color: '#FFDED1'
                }" />
              </div>

              <div
                class="col-span-2 justify-center text-6xl shadow-[0_0px_10px_0px_rgba(0,0,0,0.13)] rounded-xl p-6 bg-gray-100 sm:mb-0 mb-6">
                <h5 class="mb-4 text-base font-bold text-[#4e8d6e]">
                  Analytics For Today
                  <span class="text-[#000]">Compared To Yesterday</span>
                </h5>
                <div class="count text-sm grid xl:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-6">
                  <div class="justify-center text-6xl p-4 bg-light rounded-md relative pb-10">
                    <h5 class="text-sm pe-4 font-semibold text-[#707070] max-w-[200px]">
                      Today's Total Commission Amount
                    </h5>
                    <div class="count text-sm font-bold absolute bottom-[13px]">
                      CAF 200
                    </div>
                  </div>

                  <div class="justify-center text-6xl p-4 bg-light rounded-md relative pb-10">
                    <h5 class="text-sm pe-4 font-semibold text-[#707070] max-w-[200px]">
                      Today's MF Commission Amount
                    </h5>
                    <div class="count text-sm font-bold absolute bottom-[13px]">
                      CAF 200
                    </div>
                  </div>

                  <div class="justify-center text-6xl p-4 bg-light rounded-md relative pb-10">
                    <h5 class="text-sm pe-4 font-semibold text-[#707070] max-w-[200px]">
                      Today's Turnover Amount
                    </h5>

                    <div class="count text-sm font-bold absolute bottom-[13px]">
                      CAF 200
                    </div>
                  </div>

                  <div class="justify-center text-6xl p-4 bg-light rounded-md relative pb-10">
                    <h5 class="text-sm pe-4 font-semibold text-[#707070] max-w-[200px]">
                      Highest MF Commission
                    </h5>
                    <div class="count text-sm font-bold absolute bottom-[13px]">
                      CAF 200
                    </div>
                  </div>

                  <div class="justify-center text-6xl p-4 bg-light rounded-md relative pb-10">
                    <h5 class="text-sm pe-4 font-semibold text-[#707070] max-w-[200px]">
                      Highest MF Commission Yesterday
                    </h5>
                    <div class="count text-sm font-bold absolute bottom-[13px]">
                      CAF 120
                    </div>
                  </div>

                  <div class="justify-center text-6xl p-4 bg-light rounded-md relative pb-10">
                    <h5 class="text-sm pe-4 font-semibold text-[#707070] max-w-[200px]">
                      Highest MF Commission This Month
                    </h5>

                    <div class="count text-sm font-bold absolute bottom-[13px]">
                      CAF 800
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="col-span-2 justify-center text-6xl shadow-[0_0px_10px_0px_rgba(0,0,0,0.13)] rounded-xl p-6 bg-gray-100">
                <h5 class="mb-4 text-base font-bold text-[#000]">
                  Service Distribution
                </h5>
              </div>
              <div
                class="lg:col-span-full col-span-2 lg:flex items-center shadow-[0_0px_10px_0px_rgba(0,0,0,0.13)] rounded-xl p-6 mb-6 sm:mb-0">
                <div class="lg:w-2/3 md:w-full mt-2">
                  <h3 class="text-base font-bold">Revenue Statistics</h3>
                  <apexchart type="area" height="500" :options="chartOptions" :series="chartSeries"></apexchart>
                </div>
                <div class="lg:w-1/3 lg:grid-cols-1 sm:grid md:grid-cols-2 md:gap-4 grid-cols-1 mt-2 md:pl-3">
                  <div
                    class="justify-center text-6xl shadow-[0_0px_10px_0px_rgba(0,0,0,0.13)] rounded-xl p-6 bg-gray-100 mt-5">
                    <div class="flex justify-between w-full">
                      <h5 class="text-sm pe-4 font-semibold text-[#4e8d6e] max-w-[200px]">
                        TOTAL M.F REVENUE
                      </h5>
                      <span class="text-xl"><i class="fa-solid fa-wallet"></i></span>
                    </div>
                    <div class="count text-sm font-bold mt-6">CAF 1023</div>
                  </div>

                  <div
                    class="justify-center text-6xl shadow-[0_0px_10px_0px_rgba(0,0,0,0.13)] rounded-xl p-6 bg-gray-100 mt-5">
                    <div class="flex justify-between w-full">
                      <h5 class="text-sm pe-4 font-semibold text-[#4e8d6e] max-w-[200px]">
                        TOTAL M.F COMMISSION
                      </h5>
                      <span class="text-xl"><i class="fa-solid fa-hand-holding-dollar"></i></span>
                    </div>
                    <div class="count text-sm font-bold mt-6">CAF 1023</div>
                  </div>
                </div>
              </div>

              <div
                class="user-profile-innr lg:col-span-full col-span-2 shadow-[0_0px_10px_0px_rgba(0,0,0,0.13)] rounded-xl p-6">
                <h3 class="text-base font-bold">Revenue Statistics</h3>
                <div class="table-responsive">
                  <table class="w-full table-border mt-3">
                    <thead>
                      <th class="text-[13px] text-left p-2 text-[#434349]">
                        SERVICE
                      </th>
                      <th class="text-[13px] text-left p-2 text-[#434349]">
                        CUSTOMERS
                      </th>
                      <th class="text-[13px] text-left p-2 text-[#434349] whitespace-nowrap">
                        QUARTERLY EARNINNGS
                      </th>
                      <th class="text-[13px] text-left p-2 text-[#434349] whitespace-nowrap">
                        HALF YEAR EARNINNGS
                      </th>
                      <th class="text-[13px] text-left p-2 text-[#434349] whitespace-nowrap">
                        FULL YEAR EARNINNGS
                      </th>
                    </thead>
                    <tbody>
                      <tr class="bg-light">
                        <td>Internet</td>
                        <td>5000</td>
                        <td>3000000</td>
                        <td>10000000</td>
                        <td>50000000</td>
                      </tr>
                      <tr>
                        <td>Electricity</td>
                        <td>300</td>
                        <td>230000</td>
                        <td>13000000</td>
                        <td>5400000</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div v-if="activeTab === 'routers'" class="bg-light justify-center text-6xl rounded-xl md:p-6 p-4 bg-gray-100"
            id="routers" role="tabpanel" aria-labelledby="routers">
            <div class="min-server text-right mb-2 flex items-center">
              <select id="server-day" v-model="selected_router" @change="getHostsInfo($event)"
                class="text-[14px] max-w-[200px] ml-auto border-[0rem] bgwhite rounded-[0.4rem] px-3 py-2 border-textFieldBorder border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full focus:ring-1">
                <option value="" disabled>Select a mini server</option>
                <option value="all">All Boxes</option>
                <template v-if="filterrouter.length">
                  <option :value="option.host" v-for="option in filterrouter" :key="option.id">
                    {{ option.ssid ? option.ssid + ' - ' + option.host.split('_')[1] : option.host.split('_')[1] }}
                  </option>
                </template>
              </select>
              <span class="dotonline ml-2" v-if="selected_router && selected_router !== 'all' && info.is_online"></span>
              <span class="dotoffline ml-2"
                v-if="selected_router && selected_router !== 'all' && !info.is_online"></span>
            </div>
            <!-- tabs -->
            <div class="tabs-block" id="tabs-block">
              <div class="mb-3">
                <ul class="flex flex-wrap text-sm font-medium text-center mb-2" id="myTab"
                  data-tabs-toggle="#myTabContent" role="tablist">
                  <li class="mr-2">
                    <button class="inline-block p-4 py-2 rounded-md w-full" id="profile-tab" data-tabs-target="#profile"
                      type="button" role="tab" aria-controls="profile" aria-selected="false" @click="subActiveTab = '1'"
                      v-bind:class="[
                        subActiveTab === '1'
                          ? 'bg-[#e7e7e7] text-[#4e8d6e]'
                          : 'bg-[transparent]',
                      ]">
                      Wi-Fi Analytics
                    </button>
                  </li>
                  <li class="mr-2">
                    <button class="inline-block p-4 py-2 rounded-md w-full" id="dashboard-tab"
                      data-tabs-target="#dashboard" type="button" role="tab" aria-controls="dashboard"
                      aria-selected="false" @click="subActiveTab = '3'" v-bind:class="[
                        subActiveTab === '3'
                          ? 'bg-[#e7e7e7] text-[#4e8d6e]'
                          : 'bg-[transparent]',
                      ]">
                      Customer Insights Analytics
                    </button>
                  </li>
                  <li class="mr-2">
                    <button class="inline-block p-4 py-2 rounded-md w-full" id="settings-tab"
                      data-tabs-target="#settings" type="button" role="tab" aria-controls="settings"
                      aria-selected="false" @click="subActiveTab = '4'" v-bind:class="[
                        subActiveTab === '4'
                          ? 'bg-[#e7e7e7] text-[#4e8d6e]'
                          : 'bg-[transparent]',
                      ]">
                      Network Analytics
                    </button>
                  </li>
                  <li class="mr-2">
                    <button class="inline-block p-4 py-2 rounded-md w-full" id="profile-tab" data-tabs-target="#profile"
                      type="button" role="tab" aria-controls="profile" aria-selected="false" @click="subActiveTab = '2'"
                      v-bind:class="[
                        subActiveTab === '2'
                          ? 'bg-[#e7e7e7] text-[#4e8d6e]'
                          : 'bg-[transparent]',
                      ]">
                      MiniServer Health Analytics
                    </button>
                  </li>
                  <li class="mr-2">
                    <button class="inline-block p-4 py-2 rounded-md w-full" id="settings-tab"
                      data-tabs-target="#settings" type="button" role="tab" aria-controls="settings"
                      aria-selected="false" @click="subActiveTab = '6'" v-bind:class="[
                        subActiveTab === '6'
                          ? 'bg-[#e7e7e7] text-[#4e8d6e]'
                          : 'bg-[transparent]',
                      ]">
                      Services Status
                    </button>
                  </li>
                  <li class="mr-2">
                    <button class="inline-block p-4 py-2 rounded-md w-full" id="settings-tab"
                      data-tabs-target="#settings" type="button" role="tab" aria-controls="settings"
                      aria-selected="false" @click="subActiveTab = '5'" v-bind:class="[
                        subActiveTab === '5'
                          ? 'bg-[#e7e7e7] text-[#4e8d6e]'
                          : 'bg-[transparent]',
                      ]">
                      Router Analytics
                    </button>
                  </li>
                </ul>
              </div>
              <div id="myTabContent">
                <!-- WIFI ANALYTICS TAB SECTION -->
                <div v-if="subActiveTab === '1'"
                  class="shadow-[0_0px_10px_0px_rgba(0,0,0,0.13)] justify-center text-6xl rounded-xl md:p-6 p-4 bg-white"
                  id="profile" role="tabpanel" aria-labelledby="profile-tab">
                  <div class="mt-2">
                    <TokenAnalyticsChart :data="tokenAnalyticsData" @filterChange="fetchTokenAnalyticsData"
                      :currentTimeRange="currentTimeRange" />
                  </div>

                  <div class="w-full mt-6">
                    <a class="h-4/5 block p-4 bg-white dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
                      <h3 class="text-base font-bold">
                        Device Vendor Distribution
                      </h3>
                      <div class="mt-8">
                        <apexchart v-if="vendorChartSeries.length" width="670" type="pie" :options="vendorChartOptions"
                          :series="vendorChartSeries" :labels="vendorChartLabels">
                        </apexchart>
                        <p v-else class="text-sm">
                          No device vendor analytics available yet
                        </p>
                      </div>
                    </a>
                  </div>
                </div>
                <!-- MINI SERVER HEALTH ANALYTICS TAB SECTION -->
                <div v-if="subActiveTab === '2'"
                  class="shadow-[0_0px_10px_0px_rgba(0,0,0,0.13)] justify-center text-6xl rounded-xl md:p-6 p-4 bg-white"
                  id="profile" role="tabpanel" aria-labelledby="profile-tab">
                  <p class="text-sm text-center mb-5" v-if="selected_router === 'all'">Select a specific box to see the
                    data</p>
                  <div class="block md:grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-6">
                    <div class="justify-center">
                      <CardDesign :data="{
                        topic: 'CPU USAGE %',
                        icon: 'fa-solid fa-mobile-button',
                        figures: info.cpuUsagePercentage,
                        color: '#4AD991',
                      }" />
                    </div>

                    <div class="justify-center">
                      <CardDesign :data="{
                        topic: 'RAM MEMORY',
                        icon: 'fa-solid fa-mobile',
                        figures: info.ramMemoryPercentage,
                        color: '#FFF3D6',
                        iconcolor: '#FEC53D',
                      }" />
                    </div>

                    <div class="justify-center">
                      <CardDesign :data="{
                        topic: 'SYSTEM UPTIME',
                        icon: 'fa-solid fa-mobile',
                        figures: info.systemUptime,
                        color: '#E5E4FF',
                        iconcolor: '#8280FF',
                      }" />
                    </div>



                    <div class="justify-center">
                      <CardDesign :data="{
                        topic: 'UPLOAD SPEED',
                        icon: 'fa-solid fa-clock',
                        figures: info.networkTrafficOutMobile,
                        iconcolor: '#FF9066',
                        color: '#FFDED1'
                      }" />
                    </div>

                    <div class="justify-center">
                      <CardDesign :data="{
                        topic: 'DOWNLOAD SPEED',
                        icon: 'fa-solid fa-clock',
                        figures: info.networkTrafficInMobile,
                        color: '#4AD991',
                      }" />
                    </div>

                    <div class="justify-center">
                      <CardDesign :data="{
                        topic: 'TOTAL STORAGE SPACE USED',
                        icon: 'fa-solid fa-mobile-button',
                        figures: info.totaldiskusedperct,
                        color: '#FFF3D6',
                        iconcolor: '#FEC53D'
                      }" />
                    </div>

                    <div class="justify-center">
                      <CardDesign :data="{
                        topic: 'TOTAL RUNNING PROCESSES',
                        icon: 'fa-solid fa-mobile-button',
                        figures: info.numberofrunningprocessor,
                        color: '#E5E4FF',
                        iconcolor: '#8280FF',
                      }" />
                    </div>

                    <div class="justify-center">
                      <CardDesign :data="{
                        topic: 'TOTAL SPACE',
                        icon: 'fa-solid fa-mobile-button',
                        figures: info.total_space,
                        iconcolor: '#FF9066',
                        color: '#FFDED1'
                      }" />
                    </div>
                  </div>
                  <!-- <div class="mt-12">
                    <h3 class="text-base font-bold">Health Service Overview</h3>
                    <HealthcpuChart v-bind:graph_cpu="graph_cpu"></HealthcpuChart>
                    <HealthoutgoingChart v-bind:graph_outbound_network="graph_outbound_network"></HealthoutgoingChart>

                    <HealthincomingChart v-bind:graph_inbound_network="graph_inbound_network"></HealthincomingChart>
                    <HealthramChart v-bind:graph_ram_usage="graph_ram_usage"></HealthramChart>
                  </div> -->
                </div>
                <!-- CUSTOMER INSIGHTS ANALYTICS TAB SECTION -->
                <div v-if="subActiveTab === '3'"
                  class="shadow-[0_0px_10px_0px_rgba(0,0,0,0.13)] justify-center text-6xl rounded-xl md:p-6 p-4 bg-white"
                  id="profile" role="tabpanel" aria-labelledby="profile-tab">
                  <!-- TODO: USE WIFI ANALYTICS VERSION 2 WHICH AS ALL DATA FILTERS -->
                  <!-- <div class="flex justify-end items-center mb-3">
                    <label class="mr-2 text-[13px] font-medium">Select Filter</label>
                    <select id="day" type="name"
                      class="text-[14px] max-w-[100px] border-[0rem] bg-[#f3f6f9] rounded-[0.4rem] px-3 py-2 border-textFieldBorder border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full focus:ring-1"
                      v-model="selectedRange" @change="updateActiveData">
                      <option value="all_days">All Days</option>
                      <option value="today">Today</option>
                      <option value="one_week">1 Week</option>
                      <option value="two_weeks">2 Weeks</option>
                      <option value="thirty_days">30 Days</option>
                      <option value="sixty_days">60 Days</option>
                      <option value="ninety_days">90 Days</option>
                    </select>
                  </div> -->
                  <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
                    <div class="justify-center">
                      <CardDesign :data="{
                        topic: 'DWELL TIME',
                        icon: 'fa-solid fa-clock',
                        figures: insightAnalytics.dwell_time,
                        color: '#E5E4FF',
                        iconcolor: '#8280FF',
                      }" />
                    </div>

                    <div class="justify-center">
                      <CardDesign :data="{
                        topic: 'RETURNED CUSTOMER',
                        icon: 'fa-solid fa-user',
                        figures: insightAnalytics.returned_customer,
                        color: '#4AD991',
                      }" />
                    </div>

                    <div class="justify-center">
                      <CardDesign :data="{
                        topic: 'PEEK TIME',
                        icon: 'fa-solid fa-clock',
                        figures: insightAnalytics.peek_time,
                        color: '#FFF3D6',
                        iconcolor: '#FEC53D',
                      }" />
                    </div>

                    <div class="justify-center">
                      <CardDesign :data="{
                        topic: 'UNIQUE CUSTOMERS',
                        icon: 'fa-solid fa-user',
                        figures: insightAnalytics.unique_customer,
                        iconcolor: '#FF9066',
                        color: '#FFDED1'
                      }" />
                    </div>
                  </div>
                  <!-- <div class="mt-12">
                    <div class="w-full">
                      <h3 class="text-base font-bold">Insights Overview</h3>
                      <apexchart type="area" height="500" :options="chartOptions" :series="chartSeries"></apexchart>
                    </div>
                  </div> -->
                </div>
                <!-- NETWORK ANALYTICS TAB SECTION -->
                <div v-if="subActiveTab === '4'"
                  class="shadow-[0_0px_10px_0px_rgba(0,0,0,0.13)] justify-center text-6xl rounded-xl md:p-6 p-4 bg-white"
                  id="profile" role="tabpanel" aria-labelledby="profile-tab">
                  <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 pt-2">
                    <div class="justify-center">
                      <CardDesign :data="{
                        topic: 'NETWORK UPLOAD SPEED',
                        icon: 'fa-solid fa-mobile',
                        network_figures: `${routersAvgSpeed.average_upload_speed.toFixed(2)}  MBPS UPLOAD/${routersAvgSpeed.average_download_speed.toFixed(2)} MBPS DOWNLOAD `,
                        color: '#E5E4FF',
                        iconcolor: '#8280FF',
                        network: true,
                      }" />
                    </div>

                    <div class="justify-center">
                      <CardDesign :data="{
                        topic: 'NETWORK LATENCY',
                        icon: 'fa-solid fa-mobile-button',
                        figures: 0.00,
                        color: '#4AD991',
                      }" />
                    </div>

                    <div class="justify-center">
                      <CardDesign :data="{
                        topic: 'NETWORK USAGE',
                        icon: 'fa-solid fa-mobile-button',
                        network_figures: `${routersNetUsage.total_data_transmitted_mb.toFixed(2)} MBs UPLOADED/${routersNetUsage.total_data_received_mb.toFixed(2)} MBs DOWNLOADED`,
                        color: '#FFF3D6',
                        iconcolor: '#FEC53D',
                        network: true
                      }" />
                    </div>


                    <div class="justify-center">
                      <CardDesign :data="{
                        topic: 'SIGNAL STRENGTH',
                        icon: 'fa-solid fa-user',
                        network_figures: router_signal_strength,
                        iconcolor: '#FF9066',
                        color: '#FFDED1',
                        network: true,
                      }" />
                    </div>
                  </div>
                  <div class="mt-12">
                    <h3 class="text-base font-bold">Network Overview</h3>
                    <apexchart type="area" height="500" :options="chartOptions" :series="chartSeries"></apexchart>
                  </div>
                </div>
                <!-- ROUTER ANALYTICS TAB SECTION -->
                <div v-if="subActiveTab === '5'"
                  class="shadow-[0_0px_10px_0px_rgba(0,0,0,0.13)] justify-center text-6xl rounded-xl md:p-6 p-4 bg-white"
                  id="profile" role="tabpanel" aria-labelledby="profile-tab">
                  <!-- TODO: USE WIFI ANALYTICS VERSION 2 WHICH AS ALL DATA FILTERS -->
                  <!-- <div class="flex justify-end items-center mb-3">
                    <label class="mr-2 text-[13px] font-medium">Select Filter</label>
                    <select id="day" type="name"
                      class="text-[14px] max-w-[100px] border-[0rem] bg-[#f3f6f9] rounded-[0.4rem] px-3 py-2 border-textFieldBorder border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full focus:ring-1"
                      v-model="selectedRange" @change="updateActiveData">
                      <option value="all_days">All Days</option>
                      <option value="today">Today</option>
                      <option value="one_week">1 Week</option>
                      <option value="two_weeks">2 Weeks</option>
                      <option value="thirty_days">30 Days</option>
                      <option value="sixty_days">60 Days</option>
                      <option value="ninety_days">90 Days</option>
                    </select>
                  </div> -->
                  <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
                    <div class="justify-center">
                      <CardDesign :data="{
                        topic: 'Total Routers',
                        icon: 'fa-solid fa-wifi',
                        figures: totalRouters,
                        color: '#E5E4FF',
                        iconcolor: '#8280FF',
                      }" />
                    </div>

                    <div class="justify-center">
                      <CardDesign :data="{
                        topic: 'Sme Routers',
                        icon: 'fa-solid fa-wifi',
                        figures: smeRouters,
                        color: '#4AD991',
                      }" />
                    </div>

                    <div class="justify-center" v-if="loggedInUser.partner?.plan.name === 'Kiosk' ||
                      loggedInUser.partner?.plan.name === 'Premium'">
                      <CardDesign :data="{
                        topic: 'kiosk Routers',
                        icon: 'fa-solid fa-wifi',
                        figures: kioskRouters,
                        color: '#FFF3D6',
                        iconcolor: '#FEC53D',
                      }" />
                    </div>

                    <div class="justify-center">
                      <CardDesign :data="{
                        topic: 'Damaged Routers',
                        icon: 'fa-solid fa-wifi',
                        figures: damagedRouters,
                        iconcolor: '#FF9066',
                        color: '#FFDED1'
                      }" />
                    </div>
                  </div>
                  <!-- <div class="mt-12 grid lg:grid-cols-2 grid-cols-1 gap-6">
                    <div class="w-full">
                      <apexchart type="area" height="500" :options="RevenueChartOptions" :series="RevenueChartSeries">
                      </apexchart>
                    </div>
                    <div class="w-full">
                      <a
                        class="h-4/5 block p-4 bg-white shadow-gray-950 border-solid border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
                        <h3 class="text-base font-bold">Router Distribution</h3>
                        <div class="mt-8">
                          <apexchart width="380" type="donut" :options="routerOptions" :series="routerSeries">
                          </apexchart>
                        </div>
                      </a>
                    </div>
                  </div> -->
                </div>

                <!-- SERVICES STATUS TAB SECTION -->
                <div v-if="subActiveTab === '6'"
                  class="shadow-[0_0px_10px_0px_rgba(0,0,0,0.13)] justify-center text-6xl rounded-xl md:p-6 p-4 bg-white"
                  id="service-status" role="tabpanel" aria-labelledby="service-status-tab">
                  <p class="text-sm text-center mb-5" v-if="selected_router === 'all'">Select a specific box to see the
                    data</p>
                  <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 pt-2">
                    <div class="justify-center">
                      <CardDesign :data="{
                        topic: 'BOX LOCATION UPDATE',
                        icon: 'fa-solid fa-location-dot',
                        figures: info.boxLocationUpdateServiceStatus,
                        color: '#E5E4FF',
                        iconcolor: '#8280FF',
                        no_zero: true,
                        small: true
                      }" />
                    </div>

                    <div class="justify-center">
                      <CardDesign :data="{
                        topic: 'BOX OS VERSION UPDATE',
                        icon: 'fa fa-location',
                        figures: info.boxOSVersionUpdateServiceStatus,
                        color: '#4AD991',
                        no_zero: true,
                        small: true

                      }" />
                    </div>

                    <div class="justify-center">
                      <CardDesign :data="{
                        topic: 'CAPTIVE PORTAL DATABASE INITIALIZATION',
                        icon: 'fa-solid fa-database',
                        figures: info.captivePortalDbInitServiceStatus,
                        color: '#FFF3D6',
                        iconcolor: '#FEC53D',
                        no_zero: true,
                        small: true

                      }" />
                    </div>

                    <div class="justify-center">
                      <CardDesign :data="{
                        topic: 'CAPTIVE PORTAL DATABASE SYNC',
                        icon: 'fa-solid fa-rotate',
                        figures: info.captivePortalDbSyncServiceStatus,
                        iconcolor: '#FF9066',
                        color: '#FFDED1',
                        no_zero: true,
                        small: true

                      }" />
                    </div>


                    <div class="justify-center">
                      <CardDesign :data="{
                        topic: 'COOVACHILLI',
                        icon: 'fa-solid fa-microchip',
                        figures: info.coovaChilliServiceStatus,
                        color: '#E5E4FF',
                        iconcolor: '#8280FF',
                        no_zero: true,
                        small: true

                      }" />
                    </div>

                    <div class="justify-center">
                      <CardDesign :data="{
                        topic: 'HOSTAPD',
                        icon: 'fa-solid fa-wifi',
                        figures: info.hostapdServiceStatus,
                        color: '#4AD991',
                        no_zero: true,
                        small: true
                      }" />
                    </div>

                    <div class="justify-center">
                      <CardDesign :data="{
                        topic: 'MEDIA SYNC',
                        icon: 'fa-solid fa-file',
                        figures: info.mediaSyncServiceStatus,
                        color: '#FFF3D6',
                        iconcolor: '#FEC53D',
                        no_zero: true,
                        small: true

                      }" />
                    </div>

                    <div class="justify-center">
                      <CardDesign :data="{
                        topic: 'SSID CHANGE',
                        icon: 'fa-solid fa-signature',
                        figures: info.ssidServiceStatus,
                        iconcolor: '#FF9066',
                        color: '#FFDED1',
                        no_zero: true,
                        small: true

                      }" />
                    </div>

                    <div class="justify-center">
                      <CardDesign :data="{
                        topic: 'WEBMIN',
                        icon: 'fa-solid fa-spider',
                        figures: info.webminServiceStatus,
                        color: '#E5E4FF',
                        iconcolor: '#8280FF',
                        no_zero: true,
                        small: true

                      }" />
                    </div>

                    <div class="justify-center">
                      <CardDesign :data="{
                        topic: 'MOTIONEYE',
                        icon: 'fa-solid fa-camera',
                        figures: info.motionEyeServiceStatus,
                        color: '#4AD991',
                        no_zero: true,
                        small: true
                      }" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="activeTab === 'sms'" class="bg-light justify-center text-6xl rounded-xl md:p-6 p-4 bg-gray-100"
            id="sms" role="tabpanel" aria-labelledby="sms">
            <SMSPage />
          </div>
          <div v-if="activeTab === 'revenue-insights'"
            class="bg-light justify-center text-6xl rounded-xl md:p-6 p-4 bg-gray-100" id="revenue-insights"
            role="tabpanel" aria-labelledby="revenue-insights">
            <div
              class="shadow-[0_0px_10px_0px_rgba(0,0,0,0.13)] justify-center text-6xl rounded-xl md:p-6 p-4 bg-white">
              <!-- TODO: USE WIFI ANALYTICS VERSION 2 WHICH AS ALL DATA FILTERS -->
              <!-- <div class="flex justify-end items-center mb-3">
                <label class="mr-2 text-[13px] font-medium">Select Filter</label>
                <select id="month" type="name"
                  class="text-[14px] max-w-[130px] border-[0rem] bg-[#f3f6f9] rounded-[0.4rem] px-3 py-2 border-textFieldBorder border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full focus:ring-1"
                  v-model="selectedRevenueRange" @change="updateActiveRevenueData">
                  <option value="30">This Month</option>
                  <option value="1">Today</option>
                  <option value="7">1 Week</option>
                  <option value="14">2 Weeks</option>
                  <option value="21">3 Weeks</option>
                </select>
              </div> -->
              <div class="block md:grid grid-cols-1 gap-6">
                <div
                  class="shadow-[0_0px_10px_0px_rgba(0,0,0,0.13)] justify-center text-6xl rounded-xl p-6 bg-gray-100 md:mb-0 mb-6">
                  <div class="flex justify-between w-full">
                    <h5 class="text-sm pe-4 font-semibold text-[#4e8d6e] max-w-[200px]">
                      MONTHLY RECURRING REVENUE
                    </h5>
                  </div>
                  <div class="flex mt-8 justify-center overflow-hidden">
                    <apexchart width="800" height="300" type="area" :options="MRRRevenueChartOptions"
                      :series="MRRRevenueChartSeries">
                    </apexchart>
                  </div>
                </div>
                <div
                  class="justify-center text-6xl shadow-[0_0px_10px_0px_rgba(0,0,0,0.13)] rounded-xl p-6 bg-gray-100 md:mb-0 mb-6">
                  <div class="flex justify-between w-full">
                    <h5 class="text-sm pe-4 font-semibold text-[#4e8d6e] max-w-[200px]">
                      CUSTOMER LIFETIME VALUE
                    </h5>
                  </div>
                  <div class="flex mt-8 justify-center overflow-hidden">
                    <apexchart width="800" height="300" type="area" :options="CLVRevenueChartOptions"
                      :series="CLVRevenueChartSeries">
                    </apexchart>
                  </div>
                </div>
                <div
                  class="shadow-[0_0px_10px_0px_rgba(0,0,0,0.13)] justify-center text-6xl rounded-xl p-6 bg-gray-100 md:mb-0 mb-6">
                  <div class="flex justify-between w-full">
                    <h5 class="text-sm pe-4 font-semibold text-[#4e8d6e] max-w-[200px]">
                      CUSTOMER CHURN RATE
                    </h5>
                  </div>
                  <div class="flex mt-8 justify-center overflow-hidden">
                    <apexchart width="800" height="300" type="area" :options="ChurnRevenueChartOptions"
                      :series="ChurnRevenueChartSeries">
                    </apexchart>
                  </div>
                </div>
                <div
                  class="shadow-[0_0px_10px_0px_rgba(0,0,0,0.13)] justify-center text-6xl rounded-xl p-6 bg-gray-100 md:mb-0 mb-6">
                  <div class="flex justify-between w-full">
                    <h5 class="text-sm pe-4 font-semibold text-[#4e8d6e] max-w-[200px]">
                      CUSTOMER CONVERSION RATE
                    </h5>
                  </div>
                  <div class="flex mt-8 justify-center overflow-hidden">
                    <apexchart width="800" height="300" type="area" :options="ConversionRateChartOptions"
                      :series="ConversionRateChartSeries">
                    </apexchart>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="activeTab === 'advertviews'"
            class="bg-light justify-center text-6xl rounded-xl md:p-6 p-4 bg-gray-100" id="revenue-insights"
            role="tabpanel" aria-labelledby="revenue-insights">
            <div
              class="shadow-[0_0px_10px_0px_rgba(0,0,0,0.13)] justify-center text-6xl rounded-xl md:p-6 p-4 bg-white">
              <div class="flex justify-end items-center mb-3 text-[13px]">
                <div class="form-group mr-2">
                  <label class="after:ml-0.5 after:text-red-500 block text-[11px] font-medium text-slate-700 mb-1">
                    Routers
                  </label>
                  <select name="routers" id="router" style="
                      background: #f3f6f9;
                      border-radius: 5px;
                      padding: 8px;
                    " class="form-control" tabindex="12" @change="changeRouter">
                    <option v-for="(router, index) in list_router" :key="index" :value="router.serial_number">
                      <p v-if="router.ssid && router.serial_number">{{ router.ssid }} - {{ router.serial_number }}</p>
                      <p v-else>{{ router.serial_number }}</p>
                    </option>
                  </select>
                </div>

                <select id="day" type="name" style="margin-top: 14px"
                  class="text-[14px] max-w-[100px] border-[0rem] bg-[#f3f6f9] rounded-[0.4rem] px-3 py-2 border-textFieldBorder border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full focus:ring-1"
                  v-model="selectedRange" @change="updateDateRangesSelect">
                  <option value="all_days">All Days</option>
                  <option value="today">Today</option>
                  <option value="one_week">1 Week</option>
                  <option value="two_weeks">2 Weeks</option>
                  <option value="thirty_days">30 Days</option>
                  <option value="sixty_days">60 Days</option>
                  <option value="ninety_days">90 Days</option>
                </select>
              </div>
              <!-- ADVERT VIEWS -->
              <div class="block md:grid grid-cols-1 gap-6">
                <div
                  class="shadow-[0_0px_10px_0px_rgba(0,0,0,0.13)] justify-center text-6xl rounded-xl p-6 bg-gray-100 md:mb-0 mb-6">
                  <div class="flex justify-between w-full">
                    <h5 class="text-sm pe-4 font-semibold text-[#4e8d6e] max-w-[200px]">
                      AD VIEW ANALYTICS
                    </h5>
                  </div>
                  <div>
                    <div class="flex mt-8 justify-center overflow-hidden">
                      <Pie :data="chartData" :options="chartOptionsv2" />
                    </div>
                    <div class="mt-4 text-[13px] font-semibold text-center">
                      <p class="mb-2">
                        Total Views: {{ viewMetrics.total_views }}
                      </p>
                      <p class="mb-2">
                        Unique Viewers: {{ viewMetrics.unique_viewers }}
                      </p>
                      <p>
                        Average Views per Viewer: {{ viewMetrics.average_views_per_viewer.toFixed(2) }}
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  class="shadow-[0_0px_10px_0px_rgba(0,0,0,0.13)] justify-center text-6xl rounded-xl p-6 bg-gray-100 md:mb-0 mb-6">
                  <div class="flex justify-between w-full">
                    <h5 class="text-sm pe-4 font-semibold text-[#4e8d6e] max-w-[200px]">
                      PEAK USAGE TIMES
                    </h5>
                  </div>
                  <div class="flex mt-8 justify-center overflow-hidden">
                    <apexchart class="mt-20" width="700" type="line" :options="PeakChartOptions"
                      :series="PeakChartSeries">
                    </apexchart>
                  </div>
                </div>
                <AnalyticsMap v-bind:markers="ipMarkers" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<!-- TODO: ADD THE SAME ADVERT VIEWS ANALYTICS CHANGE TO THE SME DASHBOARD AND PUSH AS WELL -->

<script lang="ts">
import axios from 'axios';
import moment from 'moment';
import router from '@/router';
import { mapGetters } from 'vuex';
import ApexCharts from 'vue3-apexcharts';
import { defineComponent, ref, provide } from 'vue';

import { Pie } from 'vue-chartjs';
import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement } from 'chart.js';


import instance from '@/axios-interceptor';
import AnalyticsMap from './analyticsMap.vue';
import { DeviceVendor, TokenAnalyticsPayload } from '@/interfaces/router';
import { DataUsage, ConnectedClients } from '@/interfaces/router';
import DashboardHeader from '../../components/markup/DashboardHeader.vue';
import { dashboardHeadericon } from '../../helpers/stub_data/dashboard_routers';
import CardDesign from "../../components/Common/CardDesign.vue";
import SMSPage from "./SMSInsights.vue";

import TokenAnalyticsChart from '@/components/Common/TokenAnalyticsChart.vue';

import { ChartData, ChartDataset } from 'chart.js';

interface PieChartData {
  labels: string[];
  datasets: ChartDataset<'pie'>[];
}

ChartJS.register(Title, Tooltip, Legend, ArcElement);

export default defineComponent({
  name: 'AnalyticsPage',
  components: {
    DashboardHeader,
    apexchart: ApexCharts,
    AnalyticsMap,
    CardDesign,
    SMSPage,
    TokenAnalyticsChart,
    Pie,
  },
  setup() {
    const icon = ref({ ...dashboardHeadericon });
    const activeId = ref(1);
    // const activeTab = ref('profile');
    const center = ref({ lat: -1.8899577, lng: 30.0634073 });
    const markers = ref([
      {
        position: { lat: -1.8899577, lng: 30.0634073 },
      },
    ]);
    const isOpenModal = ref(false);
    const fetchingTokenAnalyticsData = ref(false);
    const currentTimeRange = '3months';
    const router_serial_number = ref<string | string[] | null>();


    provide('loadingOne', false);
    provide('loadingTwo', fetchingTokenAnalyticsData);

    return {
      activeId,
      center,
      markers,
      isOpenModal,
      // activeTab,
      icon,
      fetchingTokenAnalyticsData,
      currentTimeRange,
      router_serial_number,
    };
  },

  data() {
    return {
      startdate: '',
      enddate: '',
      realms: [] as { name: 'string'; id: string | number }[],
      selectedRouter: '',
      ipMarkers: [] as any,
      filterrouter: [
        {
          id: '',
          host: '',
          ssid: '',
        },
      ],
      list_router: [
        {
          id: 0,
          ssid: '',
          serial_number: '',
        },
      ],
      selected_router: '',
      miniServers: [
        {
          host: '',
          hostid: '',
          ssid: '',
        },
      ],
      tokenAnalyticsData: null,
      fetchingWifiAnalytics: false,
      zabbixUserName: 'Admin',
      zabbixPassword: 'wizzdev2021',
      zabbixAuthenticationToken: '',
      zabbixBaseUri: 'https://zabbix.shirikihub.com/zabbix/',
      zabbixApiEndpoint: 'api_jsonrpc.php',
      zabbixHostRequestPayload: {
        jsonrpc: '2.0',
        method: 'host.get',
        params: {
          output: ['hostid', 'host'],
          selectInterfaces: ['interfaceid', 'ip'],
        },
        auth: this.zabbixAuthenticationToken,
        id: 1,
      },
      info: {
        is_online: false,
        cpuUsagePercentage: '',
        ramMemoryPercentage: '',
        systemUptime: '',
        networkTrafficOutMobile: '',
        networkTrafficInMobile: '',
        numberofrunningprocessor: '',
        totaldiskused: '',
        total_space: '',
        totaldiskusedperct: '',
        boxLocationUpdateServiceStatus: '',
        boxOSVersionUpdateServiceStatus: '',
        captivePortalDbInitServiceStatus: '',
        captivePortalDbSyncServiceStatus: '',
        coovaChilliServiceStatus: '',
        hostapdServiceStatus: '',
        mediaSyncServiceStatus: '',
        ssidServiceStatus: '',
        webminServiceStatus: '',
        motionEyeServiceStatus: '',
      },
      graph_cpu: [],
      graph_outbound_network: [],
      graph_inbound_network: [],
      graph_ram_usage: [],
      options: {
        labels: [
          'Apple',
          'Samsung',
          'Huawei',
          'WindowsOS-2010',
          'WindowsOS-2020',
        ],
      },
      totalRouters: 0,
      functionalRouters: 0,
      damagedRouters: 0,
      smeRouters: 0,
      kioskRouters: 0,
      top10Vendors: [] as DeviceVendor[],
      vendorChartOptions: {
        labels: [] as string[],
      },
      vendorChartSeries: [] as number[],
      vendorChartLabels: [] as string[],
      labels: [
        'Apple',
        'Samsung',
        'Huawei',
        'WindowsOS-2010',
        'WindowsOS-2020',
      ],
      series: [35, 214, 123, 34, 10],
      chartOptions: {
        xaxis: {
          categories: [
            'Jan',
            'Feb',
            'March',
            'April',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec',
          ],
          title: {
            text: 'Overview',
          },
        },
        zoom: {
          type: 'x',
          enabled: true,
          autoScaleYaxis: false,
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'smooth',
        },
      },
      chartSeries: [
        {
          name: 'data used',
          data: [100, 70, 102, 77, 110, 112, 80, 78, 94, 67, 70, 180],
        },
        {
          name: 'people connected',
          data: [20, 30, 24, 50, 26, 30, 25, 15, 40, 20, 55, 60],
        },
      ],
      activeTab: 'routers',
      subActiveTab: '1',
      isLoading: false,
      tabIndex: 0,
      insightAnalytics: {} as any,
      wifiAnalytics: {} as any,
      totalMicroFranchisees: 0,
      femaleMicroFranchisees: 0,
      maleMicroFranchisees: 0,
      activeMicroFranchisees: 0,
      RevenueChartOptions: {
        xaxis: {
          categories: [
            'Jan',
            'Feb',
            'March',
            'April',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec',
          ],
          title: {
            text: 'Overview',
          },
        },
        zoom: {
          type: 'x',
          enabled: true,
          autoScaleYaxis: false,
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'smooth',
        },
      },
      RevenueChartSeries: [
        {
          name: 'Revenue Statistics',
          data: [100, 70, 102, 77, 110, 112, 80, 78, 94, 67, 70, 180],
        },
      ],
      routerchartOptions: {
        xaxis: {
          categories: ['Loading...'],
          title: {
            text: 'Overview',
          },
        },
        zoom: {
          type: 'x',
          enabled: true,
          autoScaleYaxis: false,
        },
        dataLabels: {
          enabled: true,
        },
        stroke: {
          curve: 'smooth',
        },
      },
      routerSeries: [44, 55, 23],
      routerOptions: {
        labels: ['Kiosk', 'Sme', 'Unassigned'],
      },
      selectedRange: 'all_days',
      routersAvgSpeed: {
        average_upload_speed: 0,
        average_download_speed: 0,
      },
      routersNetUsage: {
        total_data_received_mb: 0,
        total_data_transmitted_mb: 0,
      },
      router_signal_strength: 'no data available yet',
      networkUsagePerMonth: [] as DataUsage[],
      connectedClientsPerMonth: [] as ConnectedClients[],

      selectedRevenueRange: 30,
      revenueCategories: [
        '30',
        '29',
        '28',
        '27',
        '26',
        '25',
        '24',
        '23',
        '22',
        '21',
        '20',
        '19',
        '18',
        '17',
        '16',
        '15',
        '14',
        '13',
        '12',
        '11',
        '10',
        '9',
        '8',
        '7',
        '6',
        '5',
        '4',
        '3',
        '2',
        '1',
      ],
      MRRData: [
        100, 70, 102, 77, 110, 112, 80, 78, 94, 67, 70, 180, 47, 55, 100, 70,
        102, 77, 110, 112, 80, 78, 94, 67, 70, 180, 47, 55, 180, 102,
      ],
      MRRRevenueChartOptions: {
        chart: {
          id: 'mrr-analytics-chart',
        },
        xaxis: {
          tickPlacement: 'on',
          categories: [
            '30',
            '29',
            '28',
            '27',
            '26',
            '25',
            '24',
            '23',
            '22',
            '21',
            '20',
            '19',
            '18',
            '17',
            '16',
            '15',
            '14',
            '13',
            '12',
            '11',
            '10',
            '9',
            '8',
            '7',
            '6',
            '5',
            '4',
            '3',
            '2',
            '1',
          ],
          title: {
            text: 'MRR Statistics (Days)',
          },
        },
        dataLabels: {
          enabled: true,
        },
        stroke: {},
      },
      MRRRevenueChartSeries: [
        {
          name: 'Revenue Statistics',
          data: [
            100, 70, 102, 77, 110, 112, 80, 78, 94, 67, 70, 180, 47, 55, 100,
            70, 102, 77, 110, 112, 80, 78, 94, 67, 70, 180, 47, 55, 180, 102,
          ],
        },
      ],
      CLVData: [
        70, 102, 70, 67, 180, 78, 112, 100, 77, 80, 94, 110, 102, 55, 47, 70,
        102, 70, 67, 180, 78, 112, 100, 77, 80, 94, 110, 102, 55, 47,
      ],
      CLVRevenueChartOptions: {
        chart: {
          id: 'cltv-analytics-chart',
        },
        xaxis: {
          tickPlacement: 'on',
          categories: [
            '30',
            '29',
            '28',
            '27',
            '26',
            '25',
            '24',
            '23',
            '22',
            '21',
            '20',
            '19',
            '18',
            '17',
            '16',
            '15',
            '14',
            '13',
            '12',
            '11',
            '10',
            '9',
            '8',
            '7',
            '6',
            '5',
            '4',
            '3',
            '2',
            '1',
          ],
          title: {
            text: 'CLTV Statistics (Days)',
          },
        },
        dataLabels: {
          enabled: true,
        },
        stroke: {},
      },
      CLVRevenueChartSeries: [
        {
          name: 'Revenue Statistics',
          data: [
            70, 102, 70, 67, 180, 78, 112, 100, 77, 80, 94, 110, 102, 55, 47,
            70, 102, 70, 67, 180, 78, 112, 100, 77, 80, 94, 110, 102, 55, 47,
          ],
        },
      ],
      ChurnData: [
        70, 102, 70, 67, 180, 78, 112, 100, 77, 80, 94, 110, 77, 40, 33, 70,
        102, 70, 67, 180, 78, 112, 100, 77, 80, 94, 110, 77, 40, 33,
      ],
      ChurnRevenueChartOptions: {
        chart: {
          id: 'churn-analytics-chart',
        },
        xaxis: {
          categories: [
            '30',
            '29',
            '28',
            '27',
            '26',
            '25',
            '24',
            '23',
            '22',
            '21',
            '20',
            '19',
            '18',
            '17',
            '16',
            '15',
            '14',
            '13',
            '12',
            '11',
            '10',
            '9',
            '8',
            '7',
            '6',
            '5',
            '4',
            '3',
            '2',
            '1',
          ],
          title: {
            text: 'Churn Statistics (Days)',
          },
        },
        zoom: {
          type: 'x',
          enabled: true,
          autoScaleYaxis: false,
        },
        dataLabels: {
          enabled: true,
        },
        stroke: {
          curve: 'smooth',
        },
      },
      ViewersChartOptions: {
        chart: {
          id: 'mrr-analytics-chart',
        },
        xaxis: {
          tickPlacement: 'on',
          categories: [] as string[],
          title: {
            text: 'Total Ad Viewers (Days)',
          },
        },
        dataLabels: {
          enabled: true,
        },
        stroke: {},
      },
      ViewersChartSeries: [
        {
          name: 'Total Views',
          data: [
            100, 70, 102, 77, 110, 112, 80, 78, 94, 67, 70, 180, 47, 55, 100,
            70, 102, 77, 110, 112, 80, 78, 94, 67, 70, 180, 47, 55, 180, 102,
          ],
        },
      ],
      UniqueData: [
        70, 102, 70, 67, 180, 78, 112, 100, 77, 80, 94, 110, 102, 55, 47, 70,
        102, 70, 67, 180, 78, 112, 100, 77, 80, 94, 110, 102, 55, 47,
      ],
      UniqueChartOptions: {
        chart: {
          id: 'cltv-analytics-chart',
        },
        xaxis: {
          tickPlacement: 'on',
          categories: [
            '30',
            '29',
            '28',
            '27',
            '26',
            '25',
            '24',
            '23',
            '22',
            '21',
            '20',
            '19',
            '18',
            '17',
            '16',
            '15',
            '14',
            '13',
            '12',
            '11',
            '10',
            '9',
            '8',
            '7',
            '6',
            '5',
            '4',
            '3',
            '2',
            '1',
          ],
          title: {
            text: 'Unique Ad Viewers (Days)',
          },
        },
        dataLabels: {
          enabled: true,
        },
        stroke: {},
      },
      UniqueChartSeries: [
        {
          name: 'Unique Views',
          data: [
            70, 102, 70, 67, 180, 78, 112, 100, 77, 80, 94, 110, 102, 55, 47,
            70, 102, 70, 67, 180, 78, 112, 100, 77, 80, 94, 110, 102, 55, 47,
          ],
        },
      ],
      viewMetrics: {
        total_views: 0,
        unique_viewers: 0,
        average_views_per_viewer: 0,
      },
      pieData: [] as number[],
      chartOptionsv2: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: 'top',
          },
        },
      },
      PiechartOptions: {
        chart: {
          id: 'ad-views-donut-chart',
        },
        labels: ['Total Views', 'Unique Viewers'],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: 'bottom',
              },
            },
          },
        ],
      },
      PeakData: [] as number[],
      PeakChartOptions: {
        chart: {
          id: 'churn-analytics-chart',
        },
        xaxis: {
          categories: [] as string[],
          title: {
            text: 'Peak Usage Time (Days)',
          },
        },
        zoom: {
          type: 'x',
          enabled: true,
          autoScaleYaxis: false,
        },
        dataLabels: {
          enabled: true,
        },
        stroke: {
          curve: 'smooth',
        },
      },
      PeakChartSeries: [
        {
          name: 'Peak Time',
          data: [] as number[],
        },
      ],
      ChurnRevenueChartSeries: [
        {
          name: 'Revenue Statistics',
          data: [
            70, 102, 70, 67, 180, 78, 112, 100, 77, 80, 94, 110, 77, 40, 33, 70,
            102, 70, 67, 180, 78, 112, 100, 77, 80, 94, 110, 77, 40, 33,
          ],
        },
      ],
      ConversionRateData: [
        70, 102, 70, 67, 180, 78, 112, 100, 77, 80, 94, 110, 77, 33, 45, 70,
        102, 70, 67, 180, 78, 112, 100, 77, 80, 94, 110, 77, 33, 45,
      ],
      ConversionRateChartOptions: {
        chart: {
          id: 'conversion-analytics-chart',
        },
        xaxis: {
          categories: [
            '30',
            '29',
            '28',
            '27',
            '26',
            '25',
            '24',
            '23',
            '22',
            '21',
            '20',
            '19',
            '18',
            '17',
            '16',
            '15',
            '14',
            '13',
            '12',
            '11',
            '10',
            '9',
            '8',
            '7',
            '6',
            '5',
            '4',
            '3',
            '2',
            '1',
          ],
          title: {
            text: 'Conversion Statistics (Days)',
          },
        },
        zoom: {
          type: 'x',
          enabled: true,
          autoScaleYaxis: false,
        },
        dataLabels: {
          enabled: true,
        },
        stroke: {
          curve: 'smooth',
        },
      },
      ConversionRateChartSeries: [
        {
          name: 'Revenue Statistics',
          data: [
            70, 102, 70, 67, 180, 78, 112, 100, 77, 80, 94, 110, 77, 33, 45, 70,
            102, 70, 67, 180, 78, 112, 100, 77, 80, 94, 110, 77, 33, 45,
          ],
        },
      ],
    };
  },
  computed: {
    ...mapGetters(['loggedInUser', 'accessToken']),
    today() {
      return moment(Date.now()).format('MMMM Do');
    },
    chartData(): PieChartData {
      return {
        labels: ['Total Views', 'Unique Viewers'],
        datasets: [
          {
            label: 'Pie Dataset',
            data: this.pieData,
            backgroundColor: ['#4BC0C0', '#9966FF'],
          },
        ],
      };
    },
  },

  methods: {
    setActiveTab(tab: string) {
      this.activeTab = tab;
      router.push({ query: { tab: tab } });
    },
    changeRouter(e: any) {
      let selecytedval = e?.target?.value;
      if (selecytedval) {
        this.selectedRouter = e?.target?.value;
        this.fetchData();
      }
    },
    updateDateRangesSelect(): void {
      // Get the start and end dates based on the selected option value
      this.ipMarkers = [];
      const { startDate, endDate } = this.getDateRange(this.selectedRange);
      this.startdate =
        this.selectedRange !== 'all_days'
          ? startDate.toISOString().slice(0, 10)
          : '';
      this.enddate =
        this.selectedRange !== 'all_days'
          ? endDate.toISOString().slice(0, 10)
          : '';
      if (this.selectedRange === 'today') {
        this.fetchData(1);
      } else {
        this.fetchData();
      }
      // You can use startDate and endDate as needed in your application
    },
    getDateRange(selectedValue: string): { startDate: Date; endDate: Date } {
      const today: Date = new Date();
      let startDate: Date = new Date();
      let endDate: Date = new Date();

      switch (selectedValue) {
        case 'today':
          startDate = new Date(
            today.getFullYear(),
            today.getMonth(),
            today.getDate()
          );
          endDate = new Date(
            today.getFullYear(),
            today.getMonth(),
            today.getDate(),
            23,
            59,
            59
          );
          break;
        case 'one_week':
          startDate = new Date(
            today.getFullYear(),
            today.getMonth(),
            today.getDate() - 6
          );
          break;
        case 'two_weeks':
          startDate = new Date(
            today.getFullYear(),
            today.getMonth(),
            today.getDate() - 13
          );
          break;
        case 'thirty_days':
          startDate = new Date(
            today.getFullYear(),
            today.getMonth(),
            today.getDate() - 29
          );
          break;
        case 'sixty_days':
          startDate = new Date(
            today.getFullYear(),
            today.getMonth(),
            today.getDate() - 59
          );
          break;
        case 'ninety_days':
          startDate = new Date(
            today.getFullYear(),
            today.getMonth(),
            today.getDate() - 89
          );
          break;
        default:
          // For 'all_days' or any other value, you can set your default range
          startDate = new Date(/* Your default start date */);
          endDate = new Date(/* Your default end date */);
          break;
      }

      return { startDate, endDate };
    },

    async fetchData(days?: number) {
      if (this.selectedRouter) {
        try {
          const response = await instance.get(
            `advert/view/analytics?router=${this.selectedRouter}&partner=${this.loggedInUser.partner.id}${this.startdate && this.enddate
              ? `&end_date=${this.enddate}&start_date=${this.startdate}${days ? `&days=${days}` : ''}`
              : ''
            }`
          )

          if (response.status === 200) {
            const { view_metrics, peak_usage_hours, viewer_locations } = response.data;

            // Update pieData
            this.pieData = [view_metrics.total_views, view_metrics.unique_viewers]

            // Update viewMetrics
            this.viewMetrics = { ...view_metrics }

            // Update PeakChartSeries
            this.PeakChartSeries = [
              {
                ...this.PeakChartSeries[0],
                data: Object.values(peak_usage_hours),
              },
            ];

            // Update PeakChartOptions
            this.PeakChartOptions = {
              ...this.PeakChartOptions,
              xaxis: {
                ...this.PeakChartOptions.xaxis,
                categories: Object.keys(peak_usage_hours),
              },
            };

            // Update ipMarkers
            this.ipMarkers = viewer_locations.map((location: any) =>
              [...location.coordinates].reverse()
            );
          } else {
            this.$toast.error('Could not fetch analytics', {
              position: 'top-right',
            })
          }
        } catch (error) {
          console.error('Error fetching data: ', error)
        }
      }
    },
    chartSeriesForNetUsage() {
      const months = this.chartOptions.xaxis.categories;
      const dataUsedSeries = months.map((month) => {
        const matchingData = this.networkUsagePerMonth.find(
          (item: any) => this.convertMonth(item.month) === month
        );

        if (matchingData !== undefined) {
          return (
            matchingData.data_received.toFixed(1) +
            matchingData.data_received.toFixed(1)
          );
        } else {
          return 0;
        }
      });
      const peopleConnectedSeries = months.map((month) => {
        const matchingData = this.connectedClientsPerMonth.find(
          (item: any) => this.convertMonth(item.month) === month
        );

        if (matchingData !== undefined) {
          return matchingData.unique_mac_count;
        } else {
          return 0;
        }
      });

      return [
        {
          name: 'Data Used',
          data: dataUsedSeries,
        },
        {
          name: 'People Connected',
          data: peopleConnectedSeries,
        },
      ];
    },
    convertMonth(fullDate: string) {
      const dateObj = new Date(fullDate);
      const monthNames = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ];

      return monthNames[dateObj.getMonth() + 1];
    },
    async fetchRouterAnalytics(id: any) {
      await instance
        .get(`partners/${id}/router-analytics/`)
        .then((response) => {
          if (response.status === 200) {
            this.totalRouters = response.data.total_routers;
            this.functionalRouters = response.data.active_routers;
            this.damagedRouters = response.data.damaged_routers;
            this.smeRouters = response.data.sme_routers;
            this.kioskRouters = response.data.kiosk_routers;
          } else {
            this.$toast.error('Could not fetch router analytics', {
              position: 'top-right',
            });
          }
        });
    },
    async getWifianlytics(router_serial_number: string) {
      const formData = {
        router_serial_number,
        partner: this.loggedInUser.partner.id
      };

      this.isLoading = true;
      this.fetchingWifiAnalytics = true;

      instance
        .post(`analytics/wifianalytics/`, formData)
        .then((response: { data: any }) => {
          this.isLoading = false;
          this.wifiAnalytics = response.data.results;
          this.updateActiveData();

          this.fetchingWifiAnalytics = false;
        })
        .catch((error: any) => {
          this.fetchingWifiAnalytics = false;
          this.isLoading = false;
          console.log(error);
        });
    },
    async fetchTokenAnalyticsData(newTimeRange: string, router_serial_number: any) {
      const payload: TokenAnalyticsPayload = {
        partner: this.loggedInUser.partner.id,
      };

      if (Array.isArray(router_serial_number)) {
        payload.router_serial_numbers = router_serial_number;
      } else {
        payload.router_serial_number = router_serial_number;
      }

      this.currentTimeRange = newTimeRange;

      if (newTimeRange && !router_serial_number) {
        if (Array.isArray(this.router_serial_number)) {
          payload.router_serial_numbers = this.router_serial_number;
        } else if (typeof this.router_serial_number === 'string') {
          payload.router_serial_number = this.router_serial_number;
        }
      }

      if (router_serial_number) {
        this.router_serial_number = router_serial_number;
      }

      try {
        this.fetchingTokenAnalyticsData = true;
        const response = await instance.post(`/analytics/wifi-v2/?time_range=${newTimeRange}`, payload);
        this.tokenAnalyticsData = response.data;
        this.fetchingTokenAnalyticsData = false;
        console.log("ANALYTICS DATA FETCHED: ", this.tokenAnalyticsData);
      } catch (error) {
        this.fetchingTokenAnalyticsData = false;
        console.error('Error fetching data:', error);
      }
    },
    async fetchCustomerInsights(router_serial_number: string | string[]) {
      const payload: TokenAnalyticsPayload = {
        partner: this.loggedInUser.partner.id,
      };

      if (Array.isArray(router_serial_number)) {
        payload.router_serial_numbers = router_serial_number;
      } else {
        payload.router_serial_number = router_serial_number;
      }

      console.log("FETCHING WIFI INSIGHT ANALYTICS");

      this.isLoading = true;

      instance
        .post(`analytics/insight/`, payload)
        .then((response: { data: any }) => {
          this.isLoading = false;
          this.insightAnalytics = response.data.results;
          this.updateActiveData();

          console.log("WIFI INSIGHT ANALYTICS DATA FETCHED AND UDPATED: ", this.insightAnalytics);

        })
        .catch((error: any) => {
          this.isLoading = false;
          console.log(error);
        });
    },
    async fetchMicroFranchiseeAnalytics(id: any) {
      await instance.get(`partners/${id}/user-analytics/`).then((response) => {
        if (response.status === 200) {
          this.totalMicroFranchisees = response.data.total_micro_franchisees;
          this.femaleMicroFranchisees = response.data.female_micro_franchisees;
          this.maleMicroFranchisees = response.data.male_micro_franchisees;
          this.activeMicroFranchisees = response.data.active_micro_franchisees;
        } else {
          this.$toast.error('Could not fetch micro franchisee analytics.', {
            position: 'top-right',
          });
        }
      });
    },
    async loginZabbix() {
      console.log('ZABBIX LOGIN STARTED');

      // TODO: WHEN THE ROUTER CHANGES THE ANALYTICS DATA DOES NOT. FIX THE ISSUE
      // TODO: FIX THE - UNDEFINED ROUTER ISSUE IN THE SELECT ROUTER LIST

      var routerUrl = `routers/?partner=${this.loggedInUser.partner.id}`;
      await instance.get(routerUrl).then((response: any) => {
        console.log('GET ROUTERS REQUEST');
        if (response.status === 200) {
          console.log(`list routers - 1`, response.data.results);
          this.list_router = response.data.results;

          if (this.list_router.length) {
            this.selectedRouter = this.list_router[0].serial_number;
          }


          const extraRouter = {
            "id": 15,
            "sme": {
              "id": 18,
              "name": "Simba Super Market",
              "is_active": true,
              "has_captive_portal_access": true,
              "has_rating_feature_enabled": false,
              "has_voucher_sms_feature_enabled": false,
              "has_food_drink_menu_feature_enabled": false,
              "has_motion_eye_feature_enabled": false,
              "has_autoconnect_addon_enabled": false,
              "has_table_reservation_addon_enabled": false
            },
            "realm": {
              "id": 23,
              "name": "Simba Super Market Realm",
              "type": "Sub Realm"
            },
            "super_realm": 63,
            "super_realm_name": "Placide Inc. Realm No.2",
            "partner": {
              "id": 10,
              "name": "Placide Inc.",
              "is_active": true,
              "has_captive_portal_access": true,
              "has_rating_feature_enabled": true,
              "has_food_drink_menu_feature_enabled": true,
              "has_voucher_sms_feature_enabled": true
            },
            "has_kiosk": false,
            "router_kiosk": {},
            "has_been_assigned_sme": true,
            "deployed_to": "Sme",
            "captive_portal": {
              "id": 11,
              "created": "2023-06-06T00:20:15.250378+02:00",
              "updated": "2024-02-14T15:39:18.830736+02:00",
              "name": "KFC",
              "primary_message": "DELICIOUS MEALS",
              "background_color": "#b21035",
              "logo": "/media/captive-portal-logo/realms/23/55e6937c-974e-48fd-ad55-c8cee2dc1876.png",
              "unique_id": "55e6937c-974e-48fd-ad55-c8cee2dc1876",
              "is_active": true,
              "is_customizable": true,
              "router": 15
            },
            "has_been_assigned_xmrig": false,
            "created": "2022-10-10T12:58:30.713018+02:00",
            "updated": "2024-02-28T14:20:38.545132+02:00",
            "serial_number": "1371172",
            "nasidentifier": "nas21",
            "last_contact": null,
            "last_contact_ip": null,
            "model_number": "PC Engines apu3",
            "ip_address": "41.186.78.100",
            "imei": "864172042521627",
            "os_version_id": "0.37.3",
            "ssid": "New Cadillac",
            "monitor": true,
            "assigned_at": "2023-12-18T09:48:08.301247+02:00",
            "active": true,
            "location": "Delivered",
            "mac_address": null,
            "location_name": null,
            "lon": 30.0588,
            "lat": -1.95,
            "functional": true
          };

          // this.list_router.push(extraRouter)

          console.log(`list routers`, this.list_router);

          console.log(`list routers`, this.list_router);
        }
      });

      delete axios.defaults.headers.common['Authorization'];
      const data = {
        jsonrpc: '2.0',
        method: 'user.login',
        params: {
          user: this.zabbixUserName,
          password: this.zabbixPassword,
        },
        id: 1,
      };

      const endpoint = `${this.zabbixBaseUri}${this.zabbixApiEndpoint}`;
      await axios.post(endpoint, data).then((response: any) => {
        if (response.status === 200 && response.data.result.length) {
          this.zabbixAuthenticationToken = response.data.result;
        } else {
          this.$toast.error(`Could not authenticate w/Zabbix.`, {
            position: 'top-right',
          });
        }
      });
      var payload_get_host = {
        jsonrpc: '2.0',
        method: 'host.get',
        params: {
          output: ['hostid', 'host'],
          selectInterfaces: ['interfaceid', 'ip'],
        },
        auth: this.zabbixAuthenticationToken,
        id: 1,
      };
      await axios
        .post(
          `${this.zabbixBaseUri}${this.zabbixApiEndpoint}`,
          payload_get_host
        )
        .then((response: any) => {
          if (response.status === 200 && response.data.result.length) {
            console.log('ZABBIX MINI SERVERS: ', response.data.result);
            this.miniServers = response.data.result;
            let routers: any = [];

            for (let i = 0; i < this.list_router.length; i++) {
              let matchingMiniServers = this.miniServers.filter(
                (item) =>
                  item.host ===
                  'mini-server_' + this.list_router[i].serial_number
              );

              if (matchingMiniServers.length) {
                matchingMiniServers[0].ssid = this.list_router[i].ssid
                routers.push(matchingMiniServers[0]);
              }
            }
            console.log('----', routers);
            this.filterrouter = routers;
          } else {
            this.$toast.error(`Could not get the hosts.`, {
              position: 'top-right',
            });
          }
        });
    },
    async getHostsInfo(event: any) {
      const targetRouterSerialNumber = event.target.value.includes(
        'mini-server'
      )
        ? event.target.value.split('_')[1]
        : event.target.value;

      let targetRouter: any = [];

      if (targetRouterSerialNumber === 'all') {
        this.info = {
          is_online: false,
          cpuUsagePercentage: '',
          ramMemoryPercentage: '',
          systemUptime: '',
          networkTrafficOutMobile: '',
          networkTrafficInMobile: '',
          numberofrunningprocessor: '',
          totaldiskused: '',
          total_space: '',
          totaldiskusedperct: '',
          boxLocationUpdateServiceStatus: '',
          boxOSVersionUpdateServiceStatus: '',
          captivePortalDbInitServiceStatus: '',
          captivePortalDbSyncServiceStatus: '',
          coovaChilliServiceStatus: '',
          hostapdServiceStatus: '',
          mediaSyncServiceStatus: '',
          ssidServiceStatus: '',
          webminServiceStatus: '',
          motionEyeServiceStatus: '',
        };
        const allSerialNumbers = this.list_router.map(router => router.serial_number);
        await this.fetchTokenAnalyticsData(this.currentTimeRange, allSerialNumbers);
        await this.fetchCustomerInsights(allSerialNumbers);
        await this.fetchRouterSpeedAnalytics(allSerialNumbers);
        await this.fetchNetworkUsageAnalytics(allSerialNumbers);
        return;
      } else {
        targetRouter = this.list_router.filter(
          (router) => router.serial_number === targetRouterSerialNumber
        );

      }

      await this.getWifianlytics(targetRouter[0].serial_number);
      await this.fetchTokenAnalyticsData(this.currentTimeRange, targetRouter[0].serial_number);
      await this.fetchCustomerInsights(targetRouter[0].serial_number);
      await this.fetchVendorAnalytics(targetRouter[0].id);
      await this.fetchRouterSpeedAnalytics(targetRouter[0].serial_number);
      await this.fetchNetworkUsageAnalytics(targetRouter[0].serial_number);
      await this.fetchNetworkUsageAndConnectedClientsAnalytics(targetRouter[0].id);
      await this.fetchRouterSignalStrengthAnalytic(targetRouter[0].serial_number);

      console.log('ZABBIX GET HOST INFO');

      const miniServerName = event.target.value;
      const host = this.miniServers.filter(
        (x: any) => x.host === miniServerName
      )[0];
      const hostid = host.hostid;

      this.selected_router = host.host;

      console.log('ZABBIX HOST ID: ', hostid);

      var payload_cpu = {
        jsonrpc: '2.0',
        method: 'item.get',
        params: {
          output: 'extend',
          hostids: hostid,
          search: { key_: 'system.cpu.util' },
        },
        auth: this.zabbixAuthenticationToken,
        id: 1,
      };

      delete instance.defaults.headers.common['Authorization'];

      await axios
        .post(`${this.zabbixBaseUri}${this.zabbixApiEndpoint}`, payload_cpu)
        .then((response) => {
          console.log('ZABBIX SERVER DATA - 2: ', response.data.result);
          if (response.status === 200 && response.data.result.length) {
            this.info.cpuUsagePercentage =
              parseFloat(response.data.result[0].lastvalue).toFixed(2) + '%';
            console.log(
              'ZABBIX HOST - CPU USAGE PERCENTAGE',
              response.data.result
            );
          } else {
            console.log('Could not get: ZABBIX HOST - CPU USAGE PERCENTAGE');

            // this.$toast.error(`Could not get: ZABBIX HOST - CPU USAGE PERCENTAGE`, {
            //   position: 'top-right',
            // });
          }
        });

      var payload_is_connected = {
        jsonrpc: '2.0',
        method: 'item.get',
        params: {
          output: 'extend',
          hostids: hostid,
          search: {
            key_: 'zabbix[host,agent,available]',
            name: 'Linux: Zabbix agent availability',
          },
        },
        auth: this.zabbixAuthenticationToken,
        id: 1,
      };
      await axios
        .post(
          `${this.zabbixBaseUri}${this.zabbixApiEndpoint}`,
          payload_is_connected
        )
        .then((response: any) => {
          console.log('ZABBIX RESPONSE: ', response.data.result);
          if (response.status === 200 && response.data.result.length) {
            let online = response.data.result[0].lastvalue;
            console.log('this is online or offline status', online);
            if (online === '1') {
              this.info.is_online = true;

              console.log('Online');
            } else {
              this.info.is_online = false;
              console.log('Offline');
            }
            console.log(response.data.result);
          } else {
            console.log('Could not get: ZABBIX HOST - ONLINE STATUS');

            // this.$toast.error("Could not get: ZABBIX HOST - ONLINE STATUS", {
            //   position: 'top-right',
            // });
          }
        });


      // SERVICES STATUS

      console.log("BOX ONLINE STATUS: ", this.info.is_online);

      if (this.info.is_online) {
        // BOX LOCATION SERVICE STATUS

        const boxLocationPayload = {
          jsonrpc: '2.0',
          method: 'item.get',
          params: {
            output: 'extend',
            hostids: hostid,
            search: { key_: 'systemd.service.active_state["box-location-update.service"]' },
          },
          auth: this.zabbixAuthenticationToken,
          id: 1,
        };

        await axios
          .post(
            `${this.zabbixBaseUri}${this.zabbixApiEndpoint}`,
            boxLocationPayload
          )
          .then((response: any) => {
            if (response.status === 200 && response.data.result.length) {
              console.log('BOX LOCATION UPDATE LAST VALUE: ', response.data.result[0].lastvalue);

              if (response.data.result[0].lastvalue === '1') {
                this.info.boxLocationUpdateServiceStatus = 'active'
              } else {
                this.info.boxLocationUpdateServiceStatus = 'inactive'
              }
            } else {
              console.log('Could not get: ZABBIX HOST - BOX LOCATION UPDATE');
            }
          });

        // BOX LOCATION SERVICE STATUS

        const boxOSVersionPayload = {
          jsonrpc: '2.0',
          method: 'item.get',
          params: {
            output: 'extend',
            hostids: hostid,
            search: { key_: 'systemd.service.active_state["box-os-version-update.service"]' },
          },
          auth: this.zabbixAuthenticationToken,
          id: 1,
        };

        await axios
          .post(
            `${this.zabbixBaseUri}${this.zabbixApiEndpoint}`,
            boxOSVersionPayload
          )
          .then((response: any) => {
            if (response.status === 200 && response.data.result.length) {
              console.log('BOX OS VERSION UPDATE LAST VALUE: ', response.data.result[0].lastvalue);

              if (response.data.result[0].lastvalue === '1') {
                this.info.boxOSVersionUpdateServiceStatus = 'active'
              } else {
                this.info.boxOSVersionUpdateServiceStatus = 'inactive'
              }
            } else {
              console.log('Could not get: ZABBIX HOST - BOX OS VERSION UPDATE');
            }
          });


        // CAPTIVE PORTAL DB INIT SERVICE STATUS

        const captivePortalDbInitPayload = {
          jsonrpc: '2.0',
          method: 'item.get',
          params: {
            output: 'extend',
            hostids: hostid,
            search: { key_: 'systemd.service.active_state["captive-portal-db-init.service"]' },
          },
          auth: this.zabbixAuthenticationToken,
          id: 1,
        };

        await axios
          .post(
            `${this.zabbixBaseUri}${this.zabbixApiEndpoint}`,
            captivePortalDbInitPayload
          )
          .then((response: any) => {
            if (response.status === 200 && response.data.result.length) {
              console.log('CAPTIVE PORTAL DB INIT LAST VALUE: ', response.data.result[0].lastvalue);

              if (response.data.result[0].lastvalue === '1') {
                this.info.captivePortalDbInitServiceStatus = 'active'
              } else {
                this.info.captivePortalDbInitServiceStatus = 'inactive'
              }
            } else {
              console.log('Could not get: ZABBIX HOST - CAPTIVE PORTAL DB INIT');
            }
          });


        // CAPTIVE PORTAL DB SYNC SERVICE STATUS

        const captivePortalDbSyncPayload = {
          jsonrpc: '2.0',
          method: 'item.get',
          params: {
            output: 'extend',
            hostids: hostid,
            search: { key_: 'systemd.service.active_state["captive-portal-db-sync.service"]' },
          },
          auth: this.zabbixAuthenticationToken,
          id: 1,
        };

        await axios
          .post(
            `${this.zabbixBaseUri}${this.zabbixApiEndpoint}`,
            captivePortalDbSyncPayload
          )
          .then((response: any) => {
            if (response.status === 200 && response.data.result.length) {
              console.log('CAPTIVE PORTAL DB SYNC LAST VALUE: ', response.data.result[0].lastvalue);

              if (response.data.result[0].lastvalue === '1') {
                this.info.captivePortalDbSyncServiceStatus = 'active'
              } else {
                this.info.captivePortalDbSyncServiceStatus = 'inactive'
              }
            } else {
              console.log('Could not get: ZABBIX HOST - CAPTIVE PORTAL DB SYNC');
            }
          });


        // COOVACHILLI SERVICE STATUS

        const chilliPayload = {
          jsonrpc: '2.0',
          method: 'item.get',
          params: {
            output: 'extend',
            hostids: hostid,
            search: { key_: 'systemd.service.active_state["chilli.service"]' },
          },
          auth: this.zabbixAuthenticationToken,
          id: 1,
        };

        await axios
          .post(
            `${this.zabbixBaseUri}${this.zabbixApiEndpoint}`,
            chilliPayload
          )
          .then((response: any) => {
            if (response.status === 200 && response.data.result.length) {
              console.log('COOVACHILLI LAST VALUE: ', response.data.result[0].lastvalue);

              if (response.data.result[0].lastvalue === '1') {
                this.info.coovaChilliServiceStatus = 'active'
              } else {
                this.info.coovaChilliServiceStatus = 'inactive'
              }
            } else {
              console.log('Could not get: ZABBIX HOST - COOVA CHILLI');
            }
          });


        // HOSTAPD SERVICE STATUS

        const hostapdPayload = {
          jsonrpc: '2.0',
          method: 'item.get',
          params: {
            output: 'extend',
            hostids: hostid,
            search: { key_: 'systemd.service.active_state["hostapd.service"]' },
          },
          auth: this.zabbixAuthenticationToken,
          id: 1,
        };

        await axios
          .post(
            `${this.zabbixBaseUri}${this.zabbixApiEndpoint}`,
            hostapdPayload
          )
          .then((response: any) => {
            if (response.status === 200 && response.data.result.length) {
              console.log('HOSTAPD LAST VALUE: ', response.data.result[0].lastvalue);

              if (response.data.result[0].lastvalue === '1') {
                this.info.hostapdServiceStatus = 'active'
              } else {
                this.info.hostapdServiceStatus = 'inactive'
              }
            } else {
              console.log('Could not get: ZABBIX HOST - HOSTAPD');
            }
          });


        // MEDIA SYNC SERVICE STATUS

        const mediaSyncPayload = {
          jsonrpc: '2.0',
          method: 'item.get',
          params: {
            output: 'extend',
            hostids: hostid,
            search: { key_: 'systemd.service.active_state["media-sync.service"]' },
          },
          auth: this.zabbixAuthenticationToken,
          id: 1,
        };

        await axios
          .post(
            `${this.zabbixBaseUri}${this.zabbixApiEndpoint}`,
            mediaSyncPayload
          )
          .then((response: any) => {
            if (response.status === 200 && response.data.result.length) {
              console.log('MEDIA SYNC LAST VALUE: ', response.data.result[0].lastvalue);

              if (response.data.result[0].lastvalue === '1') {
                this.info.mediaSyncServiceStatus = 'active'
              } else {
                this.info.mediaSyncServiceStatus = 'inactive'
              }
            } else {
              console.log('Could not get: ZABBIX HOST - MEDIA SYNC');
            }
          });


        // SSID UPDATE SERVICE STATUS

        const ssidPayload = {
          jsonrpc: '2.0',
          method: 'item.get',
          params: {
            output: 'extend',
            hostids: hostid,
            search: { key_: 'systemd.service.active_state["ssid_run.service"]' },
          },
          auth: this.zabbixAuthenticationToken,
          id: 1,
        };

        await axios
          .post(
            `${this.zabbixBaseUri}${this.zabbixApiEndpoint}`,
            ssidPayload
          )
          .then((response: any) => {
            if (response.status === 200 && response.data.result.length) {
              console.log('SSID RUN LAST VALUE: ', response.data.result[0].lastvalue);

              if (response.data.result[0].lastvalue === '1') {
                this.info.ssidServiceStatus = 'active'
              } else {
                this.info.ssidServiceStatus = 'inactive'
              }
            } else {
              console.log('Could not get: ZABBIX HOST - SSID RUN');
            }
          });

        // WEBMIN SERVICE STATUS

        const webminPayload = {
          jsonrpc: '2.0',
          method: 'item.get',
          params: {
            output: 'extend',
            hostids: hostid,
            search: { key_: 'systemd.service.active_state["webmin.service"]' },
          },
          auth: this.zabbixAuthenticationToken,
          id: 1,
        };

        await axios
          .post(
            `${this.zabbixBaseUri}${this.zabbixApiEndpoint}`,
            webminPayload
          )
          .then((response: any) => {
            if (response.status === 200 && response.data.result.length) {
              console.log('WEBMIN LAST VALUE: ', response.data.result[0].lastvalue);

              if (response.data.result[0].lastvalue === '1') {
                this.info.webminServiceStatus = 'active'
              } else {
                this.info.webminServiceStatus = 'inactive'
              }
            } else {
              console.log('Could not get: ZABBIX HOST - WEBMIN');
            }
          });


        // MOTION EYE SERVICE STATUS

        const motionEyePayload = {
          jsonrpc: '2.0',
          method: 'item.get',
          params: {
            output: 'extend',
            hostids: hostid,
            search: { key_: 'systemd.service.active_state["motioneye-control.service"]' },
          },
          auth: this.zabbixAuthenticationToken,
          id: 1,
        };

        await axios
          .post(
            `${this.zabbixBaseUri}${this.zabbixApiEndpoint}`,
            motionEyePayload
          )
          .then((response: any) => {
            if (response.status === 200 && response.data.result.length) {
              console.log('MOTIONEYE CONTAINER LAST VALUE: ', response.data.result[0].lastvalue);

              if (response.data.result[0].lastvalue === '1') {
                this.info.motionEyeServiceStatus = 'active'
              } else {
                this.info.motionEyeServiceStatus = 'inactive'
              }
            } else {
              console.log('Could not get: ZABBIX HOST - MOTIONEYE CONTAINER');
            }
          });

        // ======================================================
      } else {
        this.info.boxLocationUpdateServiceStatus = 'offline';
        this.info.boxOSVersionUpdateServiceStatus = 'offline';
        this.info.captivePortalDbInitServiceStatus = 'offline';
        this.info.captivePortalDbSyncServiceStatus = 'offline';
        this.info.coovaChilliServiceStatus = 'offline';
        this.info.hostapdServiceStatus = 'offline';
        this.info.mediaSyncServiceStatus = 'offline';
        this.info.ssidServiceStatus = 'offline';
        this.info.webminServiceStatus = 'offline';
        this.info.motionEyeServiceStatus = 'offline';
      }

      var payload_upload = {
        jsonrpc: '2.0',
        method: 'item.get',
        params: {
          output: 'extend',
          hostids: hostid,
          search: { key_: 'net.if.out[tun2]' },
        },
        auth: this.zabbixAuthenticationToken,
        id: 1,
      };

      await axios
        .post(`${this.zabbixBaseUri}${this.zabbixApiEndpoint}`, payload_upload)
        .then((response) => {
          if (response.status === 200 && response.data.result.length) {
            this.info.networkTrafficOutMobile =
              response.data.result[0].lastvalue + ' ' + 'KB';
            console.log(response.data.result);
          } else {
            console.log(
              'Could not get: ZABBIX HOST - OUTGOING NETWORK TRAFFIC'
            );

            // this.$toast.error("Could not get: ZABBIX HOST - OUTGOING NETWORK TRAFFIC", {
            //   position: 'top-right',
            // });
          }
        });

      var payload_download = {
        jsonrpc: '2.0',
        method: 'item.get',
        params: {
          output: 'extend',
          hostids: hostid,
          search: { key_: 'net.if.in[tun2]' },
        },
        auth: this.zabbixAuthenticationToken,
        id: 1,
      };

      await axios
        .post(
          `${this.zabbixBaseUri}${this.zabbixApiEndpoint}`,
          payload_download
        )
        .then((response) => {
          if (response.status === 200 && response.data.result.length) {
            this.info.networkTrafficInMobile =
              response.data.result[0].lastvalue + ' ' + 'KB';
            console.log(response.data.result);
          } else {
            console.log(
              'Could not get Zabbix: ZABBIX HOST - INCOMING NETWORK TRAFFIC'
            );

            // this.$toast.error(
            //   "Could not get Zabbix: ZABBIX HOST - INCOMING NETWORK TRAFFIC",
            //   {
            //     position: 'top-right',
            //   }
            // );
          }
        });

      var payload_ram = {
        jsonrpc: '2.0',
        method: 'item.get',
        params: {
          output: 'extend',
          hostids: hostid,
          search: { key_: 'vm.memory.size[pused]' },
        },
        auth: this.zabbixAuthenticationToken,
        id: 1,
      };

      await axios
        .post(`${this.zabbixBaseUri}${this.zabbixApiEndpoint}`, payload_ram)
        .then((response) => {
          if (response.status === 200 && response.data.result.length) {
            this.info.ramMemoryPercentage =
              parseFloat(response.data.result[0].lastvalue).toFixed(2) + '%';

            console.log(response.data.result);
          } else {
            console.log(
              'Could not get Zabbix: ZABBIX HOST - RAM MEMORY PERCENTAGE'
            );

            // this.$toast.error(
            //   "Could not get Zabbix: ZABBIX HOST - RAM MEMORY PERCENTAGE",
            //   {
            //     position: 'top-right',
            //   }
            // );
          }
        });

      var payload_systemuptime = {
        jsonrpc: '2.0',
        method: 'item.get',
        params: {
          output: 'extend',
          hostids: hostid,
          search: { key_: 'system.uptime' },
        },
        auth: this.zabbixAuthenticationToken,
        id: 1,
      };

      await axios
        .post(
          `${this.zabbixBaseUri}${this.zabbixApiEndpoint}`,
          payload_systemuptime
        )
        .then((response) => {
          if (response.status === 200 && response.data.result.length) {
            var seconds = response.data.result[0].lastvalue;
            var hours = Math.floor(seconds / 3600);
            seconds -= hours * 3600;
            var minutes = Math.floor(seconds / 60);
            seconds -= minutes * 60;

            if (hours < 10) {
              hours = 0 + hours;
            }
            if (minutes < 10) {
              minutes = 0 + minutes;
            }
            if (seconds < 10) {
              seconds = 0 + seconds;
            }
            console.log(hours + ':' + minutes + ':' + seconds);
            this.info.systemUptime = hours + ':' + minutes + ':' + seconds;

            console.log(response.data.result);
          } else {
            console.log('Could not get: ZABBIX HOST - SYSTEM UPTIME');

            // this.$toast.error("Could not get: ZABBIX HOST - SYSTEM UPTIME", {
            //   position: 'top-right',
            // });
          }
        });

      var payload_runningprocessor = {
        jsonrpc: '2.0',
        method: 'item.get',
        params: {
          output: 'extend',
          hostids: hostid,
          search: { key_: 'system.cpu.num' },
        },
        auth: this.zabbixAuthenticationToken,
        id: 1,
      };

      await axios
        .post(
          `${this.zabbixBaseUri}${this.zabbixApiEndpoint}`,
          payload_runningprocessor
        )
        .then((response) => {
          if (response.status === 200 && response.data.result.length) {
            this.info.numberofrunningprocessor =
              response.data.result[0].lastvalue;

            console.log(response.data.result);
          } else {
            console.log(
              'Could not get: ZABBIX HOST - NUMBER OF RUNNING PROCESSOR'
            );

            // this.$toast.error(
            //   "Could not get: ZABBIX HOST - NUMBER OF RUNNING PROCESSOR",
            //   {
            //     position: 'top-right',
            //   }
            // );
          }
        });

      var payload_diskuseinpercent = {
        jsonrpc: '2.0',
        method: 'item.get',
        params: {
          output: 'extend',
          hostids: hostid,
          search: { key_: 'vfs.fs.size[/data-app,pused]' },
        },
        auth: this.zabbixAuthenticationToken,
        id: 1,
      };

      await axios
        .post(
          `${this.zabbixBaseUri}${this.zabbixApiEndpoint}`,
          payload_diskuseinpercent
        )
        .then((response) => {
          if (response.status === 200 && response.data.result.length) {
            this.info.totaldiskusedperct =
              parseFloat(response.data.result[0].lastvalue).toFixed(2) + '%';

            console.log(response.data.result);
          } else {
            console.log(
              'Could not get: ZABBIX HOST - TOTAL DISK USAGE PERCENTAGE'
            );

            // this.$toast.error(
            //   "Could not get: ZABBIX HOST - TOTAL DISK USAGE PERCENTAGE",
            //   {
            //     position: 'top-right',
            //   }
            // );
          }
        });

      var payload_totalspace = {
        jsonrpc: '2.0',
        method: 'item.get',
        params: {
          output: 'extend',
          hostids: hostid,
          search: { key_: 'vfs.fs.size[/data-app,total]' },
        },
        auth: this.zabbixAuthenticationToken,
        id: 1,
      };

      await axios
        .post(
          `${this.zabbixBaseUri}${this.zabbixApiEndpoint}`,
          payload_totalspace
        )
        .then((response) => {
          if (response.status === 200 && response.data.result.length) {
            console.log(
              'ZABBIX HOST - TOTAL DISK SPACE: ',
              response.data.result
            );

            let bytes = response.data.result[0].lastvalue;
            this.info.total_space =
              parseFloat((bytes / Math.pow(1024, 3)).toFixed(2)).toString() +
              ' ' +
              'GB';
          } else {
            console.log('Could not get: ZABBIX HOST - TOTAL DISK SPACE');

            // this.$toast.error("Could not get: ZABBIX HOST - TOTAL DISK SPACE", {
            //   position: 'top-right',
            // });
          }
        });

      var payload_cpugraph = {
        jsonrpc: '2.0',
        method: 'history.get',
        params: {
          output: 'extend',
          hostids: hostid,
          history: 0,
          sortfield: 'clock',
          itemids: '37816',
          sortorder: 'DESC',
          limit: 10,
        },
        auth: this.zabbixAuthenticationToken,
        id: 1,
      };

      await axios
        .post(
          `${this.zabbixBaseUri}${this.zabbixApiEndpoint}`,
          payload_cpugraph
        )
        .then((response) => {
          if (response.status === 200 && response.data.result.length) {
            this.graph_cpu = response.data.result;
            console.log('graph cpuuu', this.graph_cpu);
          } else {
            console.log('Could not get: ZABBIX HOST - CPU GRAPH');

            // this.$toast.error("Could not get: ZABBIX HOST - CPU GRAPH", {
            //   position: 'top-right',
            // });
          }
        });

      var payload_networkinbound = {
        jsonrpc: '2.0',
        method: 'history.get',
        params: {
          output: 'extend',
          hostids: hostid,
          history: 0,
          sortfield: 'clock',
          itemids: '37770',
          sortorder: 'DESC',
          limit: 10,
        },
        auth: this.zabbixAuthenticationToken,
        id: 1,
      };

      await axios
        .post(
          `${this.zabbixBaseUri}${this.zabbixApiEndpoint}`,
          payload_networkinbound
        )
        .then((response) => {
          if (response.status === 200 && response.data.result.length) {
            this.graph_inbound_network = response.data.result;
            console.log(
              'incoming traffic in axios',
              this.graph_inbound_network
            );
          } else {
            console.log('Could not get: ZABBIX HOST - NETWORK INBOUND GRAPH');

            // this.$toast.error(
            //   "Could not get: ZABBIX HOST - NETWORK INBOUND GRAPH",
            //   {
            //     position: 'top-right',
            //   }
            // );
          }
        });

      var payload_networkoutbound = {
        jsonrpc: '2.0',
        method: 'history.get',
        params: {
          output: 'extend',
          hostids: hostid,
          history: 0,
          sortfield: 'clock',
          itemids: '37776',
          sortorder: 'DESC',
          limit: 10,
        },
        auth: this.zabbixAuthenticationToken,
        id: 1,
      };

      await axios
        .post(
          `${this.zabbixBaseUri}${this.zabbixApiEndpoint}`,
          payload_networkoutbound
        )
        .then((response) => {
          if (response.status === 200 && response.data.result.length) {
            this.graph_outbound_network = response.data.result;
          } else {
            console.log('Could not get: ZABBIX HOST - NETWORK OUTBOUND GRAPH');

            // this.$toast.error(
            //   'Could not get: ZABBIX HOST - NETWORK OUTBOUND GRAPH',
            //   {
            //     position: 'top-right',
            //   }
            // );
          }
        });

      var payload_ramusage = {
        jsonrpc: '2.0',
        method: 'history.get',
        params: {
          output: 'extend',
          hostids: hostid,
          history: 0,
          sortfield: 'clock',
          itemids: '37813',
          sortorder: 'DESC',
          limit: 10,
        },
        auth: this.zabbixAuthenticationToken,
        id: 1,
      };

      await axios
        .post(
          `${this.zabbixBaseUri}${this.zabbixApiEndpoint}`,
          payload_ramusage
        )
        .then((response) => {
          if (response.status === 200 && response.data.result.length) {
            this.graph_ram_usage = response.data.result;
          } else {
            console.log('Could not get: ZABBIX HOST - RAM USAGE');

            // this.$toast.error("Could not get: ZABBIX HOST - RAM USAGE", {
            //   position: 'top-right',
            // });
          }
        });
    },
    async fetchVendorAnalytics(router: number) {
      const formData = {
        partner: this.loggedInUser.partner.id,
        router,
      };

      this.isLoading = true;
      instance
        .post('analytics/device-vendor/', formData)
        .then((response: { data: DeviceVendor[] }) => {
          this.isLoading = false;
          const vendors = response.data.map((data) => data.vendor);
          const devices = response.data.map((data) => data.devices);

          this.top10Vendors = response.data.slice(0, 10);
          this.vendorChartLabels = vendors;
          this.vendorChartSeries = devices;
          this.vendorChartOptions.labels = vendors;
        })
        .catch((error: any) => {
          this.isLoading = false;
          console.log(error);
        });
    },
    async fetchRouterSpeedAnalytics(router_serial_number: string | string[]) {
      const payload: TokenAnalyticsPayload = {
        partner: this.loggedInUser.partner.id,
      };

      if (Array.isArray(router_serial_number)) {
        payload.router_serial_numbers = router_serial_number;
      } else {
        payload.router_serial_number = router_serial_number;
      }

      this.isLoading = true;

      instance
        .post('analytics/router-speed/', payload)
        .then((response: { data: any }) => {
          this.isLoading = false;

          this.routersAvgSpeed = {
            average_upload_speed: response.data.average_upload_speed,
            average_download_speed: response.data.average_download_speed,
          };
        })
        .catch((error: any) => {
          this.isLoading = false;
          console.log(error);
        });
    },
    async fetchNetworkUsageAnalytics(router_serial_number: string | string[]) {
      const payload: TokenAnalyticsPayload = {
        partner: this.loggedInUser.partner.id,
      };

      if (Array.isArray(router_serial_number)) {
        payload.router_serial_numbers = router_serial_number;
      } else {
        payload.router_serial_number = router_serial_number;
      }

      this.isLoading = true;

      instance
        .post('analytics/network-usage/', payload)
        .then((response: { data: any }) => {
          this.isLoading = false;

          this.routersNetUsage = {
            total_data_received_mb: response.data.total_data_received_mb,
            total_data_transmitted_mb: response.data.total_data_transmitted_mb,
          };
        })
        .catch((error: any) => {
          this.isLoading = false;
          console.log(error);
        });
    },
    async fetchRouterSignalStrengthAnalytic(router_serial_number: string) {
      const formData = {
        partner: this.loggedInUser.partner.id,
        router_serial_number,
      };

      this.isLoading = true;
      instance
        .post('analytics/router-signal-strength/', formData)
        .then((response: { data: any }) => {
          this.isLoading = false;
          this.router_signal_strength = response.data.category;
        })
        .catch((error: any) => {
          this.isLoading = false;
          console.log(error);
        });
    },
    async fetchNetworkUsageAndConnectedClientsAnalytics(router: number) {
      const formData = {
        partner: this.loggedInUser.partner.id,
        router,
      };

      this.isLoading = true;
      instance
        .post('analytics/network-usage-and-connected-clients/', formData)
        .then((response: { data: any }) => {
          this.isLoading = false;
          this.networkUsagePerMonth = response.data.data_usage;
          this.connectedClientsPerMonth = response.data.connected_clients;

          console.log("NETWORK USAGE AND CONNECTED CLIENTS DATA FETCHED AND UDPATED: ", response.data);

        })
        .catch((error: any) => {
          this.isLoading = false;
          console.log(error);
        });
    },
    async fetchRevenueAnalytics(id: any) {
      let MRR: number[],
        CLTV: number[],
        Churn: number[],
        Conversion: number[] = [];

      // MRR
      await instance.get(`partners/${id}/calculate/`).then(async (response) => {
        if (response.status === 200) {
          const totalRevenue = response.data.total_revenue;

          await instance
            .get(`partners/${id}/user-analytics/`)
            .then((response) => {
              if (response.status === 200) {
                // MRR calc = total revenue / customers

                totalRevenue.forEach((monthly: number) => {
                  MRR.push(monthly / response.data.total_micro_franchisees);
                });

                this.MRRRevenueChartSeries[0].data = MRR;
              } else {
                this.$toast.error('Could not fetch customer analytics.', {
                  position: 'top-right',
                });
              }
            });
        } else {
          this.$toast.error('Could not fetch revenue analytics.', {
            position: 'top-right',
          });
        }
      });

      // CHURN
      await instance.get(`partners/${id}/user-analytics/`).then((response) => {
        if (response.status === 200) {
          // Churn Rate (Marketplace) = (Churned Developers) / (Start Developers)
          let previousUserCount: number,
            currUserCount = 0;

          response.data.total_micro_franchisees.forEach(
            (franchisee: number) => {
              currUserCount = franchisee;
              Churn.push(
                (currUserCount - previousUserCount) / previousUserCount
              );
              previousUserCount = currUserCount;
            }
          );

          this.ChurnRevenueChartSeries[0].data = Churn;
        } else {
          this.$toast.error('Could not fetch customer analytics.', {
            position: 'top-right',
          });
        }
      });

      // CLTV
      const totalMRR = this.MRRRevenueChartSeries[0].data;
      totalMRR.forEach((MonthlyMRR: number, index: number) => {
        // CLTV(WiFi) = (Monthly Subscription Revenue) / (Churn Rate)
        CLTV.push(MonthlyMRR / this.ChurnRevenueChartSeries[0].data[index]);
      });

      // Conversion Rate
      // Conversion Rate = (Number of Conversions) / (Number of Website Visitors) * 100
    },
    updateActiveData() {
      const range = this.selectedRange;
      this.insightAnalytics = {
        ...this.insightAnalytics,
        dwell_time: this.insightAnalytics.dwell_time_ranges[range],
        first_time_visitor:
          this.insightAnalytics.first_time_visitor_ranges[range],
        peek_time: this.insightAnalytics.peek_time_ranges[range],
        phone_model: this.insightAnalytics.phone_model_ranges[range],
        returned_customer:
          this.insightAnalytics.returned_customer_ranges[range],
        unique_customer: this.insightAnalytics.unique_customer_ranges[range],
      };
    },
    updateActiveRevenueData() {
      const range = this.selectedRevenueRange * -1;

      this.MRRRevenueChartOptions = {
        chart: {
          id: 'mrr-analytics-chart',
        },
        xaxis: {
          tickPlacement: 'on',
          categories: this.revenueCategories.slice(range),
          title: {
            text: 'MRR Statistics (Days)',
          },
        },
        dataLabels: {
          enabled: true,
        },
        stroke: {},
      };
      this.MRRRevenueChartSeries[0].data = this.MRRData.slice(range);

      this.CLVRevenueChartOptions = {
        chart: {
          id: 'cltv-analytics-chart',
        },
        xaxis: {
          tickPlacement: 'on',
          categories: this.revenueCategories.slice(range),
          title: {
            text: 'CLTV Statistics (Days)',
          },
        },
        dataLabels: {
          enabled: true,
        },
        stroke: {},
      };
      this.CLVRevenueChartSeries[0].data = this.CLVData.slice(range);

      this.ChurnRevenueChartOptions = {
        chart: {
          id: 'churn-analytics-chart',
        },
        xaxis: {
          categories: this.revenueCategories.slice(range),
          title: {
            text: 'Churn Statistics (Days)',
          },
        },
        zoom: {
          type: 'x',
          enabled: true,
          autoScaleYaxis: false,
        },
        dataLabels: {
          enabled: true,
        },
        stroke: {
          curve: 'smooth',
        },
      };
      this.ChurnRevenueChartSeries[0].data = this.ChurnData.slice(range);

      this.ConversionRateChartOptions = {
        chart: {
          id: 'conversion-analytics-chart',
        },
        xaxis: {
          categories: this.revenueCategories.slice(range),
          title: {
            text: 'Conversion Statistics (Days)',
          },
        },
        zoom: {
          type: 'x',
          enabled: true,
          autoScaleYaxis: false,
        },
        dataLabels: {
          enabled: true,
        },
        stroke: {
          curve: 'smooth',
        },
      };
      this.ConversionRateChartSeries[0].data =
        this.ConversionRateData.slice(range);
    },
  },
  async created() {
    const params = new URLSearchParams(window.location.search);
    const tab = params.get('tab');

    console.log("ACTIVE TAB: ", tab);

    if (tab) {
      this.activeTab = tab;
    }

    await this.loginZabbix();
    this.fetchMicroFranchiseeAnalytics(this.loggedInUser.partner.id);
    this.fetchRouterAnalytics(this.loggedInUser.partner.id);
    this.fetchData();
  },
});
</script>

<style scoped>
.dotonline {
  height: 15px;
  width: 15px;
  background-color: green;
  border-radius: 50%;
  display: inline-block;
  margin-left: 10px;
}

.dotoffline {
  height: 15px;
  width: 15px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
  margin-left: 10px;
}
</style>